const gsVueCustomDirectives = {
    getSelectValues: function(select) {
        if(!select) {
            return []
        }

        const result = []
        const options = select.options

        for(let i = 0; i < options.length; i++) {
            if(options[i].selected && options[i].value) {
                result.push(options[i].value)
            }
        }

        return result
    },
    filterValue: function(value, modifiers) {
        if(modifiers.number) {
            value = +value
        }

        return value
    }
}

Vue.directive("material-select-model", {
    bind: function(el, binding, vnode) {
        $(function() {
            $(el).material_select()
        })

        el["onchange"] = function() {
            const modelToBind = binding.value;
            vnode.context.$data[modelToBind] = el.multiple
                ? gsVueCustomDirectives.getSelectValues(el)
                : gsVueCustomDirectives.filterValue(el.value, binding.modifiers)
        }
    },
    unbind: function(el) {
        $(el).material_select("destroy")
    }
})

Vue.directive('cleave', {
    bind: function(el, binding) {
        const input = el.tagName === 'INPUT' ? el : el.querySelector('input')
        input._vCleave = new Cleave(input, binding.value)
    },
    unbind: function(el) {
        const input = el.tagName === 'INPUT' ? el : el.querySelector('input')
        input._vCleave.destroy()
    }
})

Vue.directive('draganddrop', {
    bind: function(el, binding) {
        el['ondragstart'] = function(event) {
            $('.v-tooltip__content').css('display', 'none')
            $('[role="tooltip"]').css('display', 'none')

            if(!$(this).hasClass('is-hover')) {
                event.preventDefault()
                return
            }

            $(this).addClass('drag-and-drop')
            binding.value.startDrag(event, binding.value.addressOrder)
        }

        el['ondragend'] = function(event) {
            $(this).removeClass('drag-and-drop')
            $(this).prev().removeClass('gs-drop-active')
        }

        el['ondragover'] = function(event) {
            $(this).addClass('drag-and-drop')
            $(this).prev().addClass('gs-drop-active')
            event.preventDefault()
        }

        el['ondragleave'] = function(event) {
            $(this).removeClass('drag-and-drop')
            $(this).prev().removeClass('gs-drop-active')
            event.preventDefault()
        }

        el['ondrop'] = function(event) {
            $(this).removeClass('drag-and-drop')
            $(this).prev().removeClass('gs-drop-active')
            binding.value.startDrop(event, binding.value.addressOrder)
        }
    },
    unbind: function(el) {
        el['ondragstart'] = el['ondragend'] = el['ondragover'] = el['ondragleave'] = el['ondrop'] = null
    }
})

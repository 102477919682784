function AxiosHandler() {
    this.urlsToAvoidLoader = [
        '/trucks/cargo_item/weight_list',
        '/trucks/user/login_web',
        'https://khwxjebmh4.execute-api.us-west-2.amazonaws.com/cargoitems',
    ];

    this.isHandlerEnabled = function(config = {}) {
        return !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled)
    };

    this.requestHandler = function(request) {
        if(this.isHandlerEnabled(request)) {
            this.setLoader(true, request.url);
            if(request.method === 'get' || request.data instanceof FormData) {
                return request;
            }

            let data = new FormData();
            for(let param in request.data) {
                data.append(param, request.data[param]);
            }

            request.data = data;
            return request;
        }
        return request
    };

    this.errorHandler = function(error) {
        if(this.isHandlerEnabled(error.config)) {
            this.setLoader(false, error.url);
        }
        return Promise.reject(error)
    };

    this.successHandler = function(response) {
        if(this.isHandlerEnabled(response.config)) {
            this.setLoader(false, response.url);
        }
        return response
    };

    this.setLoader = function(show, url) {
        if(!this.urlsToAvoidLoader.includes(url)) {
            store.state.loader = show;
        }
    }
}

const handler = new AxiosHandler();
axios.interceptors.request.use(request => handler.requestHandler(request));
axios.interceptors.response.use(
    response => handler.successHandler(response),
    error => handler.errorHandler(error)
);

// Todo: rename to 'application-theme' when using independent web client.
const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                background: '#fafafa',
                primary: '#2196f3', // Blue 500
                secondary: '#616161', // gray darken-2
                accent: '#2979ff', // Accent Blue 400
                error: '#b00020',
            },
        },
    },
});

Vue.use(VeeValidate, {
    classes: true,
    classNames: {
        valid: 'valid',
        invalid: 'invalid'
    },
    validity: true
})

VeeValidate.Validator.extend('zip_code', {
    validate: (value) => isValidZipCode(value),
    getMessage: 'You can use the format 11111, 11111-1111 or A1A 1A1, where A is a letter and 1 is a digit.'
})

Vue.component('gs-credit-card-form', {
    template: `
    <v-card>
        <validation-observer ref="formCard">
            <v-form @submit.prevent="registerCard" class="pa-10">
                <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
                    <span class="headline">Register Card</span>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col>
                            <gs-v-text-field
                                    name="name on card"
                                    label="Name on card"
                                    v-model.trim="userCardName"
                                    rules="required"></gs-v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="9">
                            <gs-v-text-field
                                    name="card number"
                                    rules="required|credit_card"
                                    v-model.trim.lazy="userCardNumber"
                                    label="Credit card number"
                                    :mask="masks.creditCard">
                            </gs-v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <gs-v-text-field
                                    name="cvv"
                                    rules="required|numeric|min:3|max:4"
                                    v-model.trim="userCardCvv"
                                    label="CVV"
                                    maxlength="4"
                                    :mask="masks.cvv"
                            ></gs-v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <gs-v-text-field
                                    name="expiration date"
                                    rules="required|expiration_date"
                                    v-model.trim="userCardExpiration"
                                    label="Expiration date"
                                    placeholder="mm/yyyy"
                                    :unmask-value="false"
                                    :mask="{date: true, datePattern: ['m', 'Y']}">
                            </gs-v-text-field>
                        </v-col>
                        <v-col>
                            <gs-v-text-field
                                    id="zipCode"
                                    multipleMasks
                                    name="billing zip code"
                                    rules="required|zip_code"
                                    v-model.trim="userCardZipCode"
                                    label="Zip code"
                                    :mask="{uppercase: true, blocks: [10]}">
                            </gs-v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="GOOGLE_RECAPTCHA_WEBSITE_KEY_CHECKBOX">
                            <vue-recaptcha
                                ref="creditCardRecaptcha"
                                @verify="recaptchaCallback"
                                @expired="expiredRecaptchaCallback"
                                :sitekey="GOOGLE_RECAPTCHA_WEBSITE_KEY_CHECKBOX">
                            </vue-recaptcha>
                        </v-col>
                    </v-row>

                    <v-btn block color="primary" type="submit" :loading="isLoading" :disabled="!googleRecaptcha">Update Card</v-btn>
                    </v-card-text>
                </v-form>
            </validation-observer>
        </v-card>`,
    data: function() {
        return {
            masks: masks,
            GOOGLE_RECAPTCHA_WEBSITE_KEY_CHECKBOX: googleRecaptchaWebsiteKeyCheckBox
        };
    },
    props: ['userData', 'isLoading'],
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    mounted: function() {
        const vm = this;
        requestBraintreeDeviceData(function(deviceData) {
            vm.deviceData = deviceData;
        });
    },
    computed: {
        deviceData: {
            get: function() {
                return this.$store.state.deviceData;
            },
            set: function(value) {
                this.$store.commit('changeDeviceData', value);
            }
        },
        googleRecaptcha: {
            get: function() {
                return this.$store.state.creditCardGoogleRecaptcha;
            },
            set: function(value) {
                this.$store.commit('changeCreditCardGoogleRecaptcha', value);
            }
        },
        userCardName: {
            get: function() {
                return this.$store.state.user.card.nameOnCard;
            },
            set: function(value) {
                this.$store.commit('changeUserCardName', value);
            }
        },
        userCardNumber: {
            get: function() {
                return this.$store.state.user.card.cardNumber;
            },
            set: function(value) {
                this.$store.commit('changeUserCardNumber', value);
            }
        },
        userCardCvv: {
            get: function() {
                return this.$store.state.user.card.cvv;
            },
            set: function(value) {
                this.$store.commit('changeUserCardCvv', value);
            }
        },
        userCardExpiration: {
            get: function() {
                return this.$store.state.user.card.expirationDate;
            },
            set: function(value) {
                this.$store.commit('changeUserCardExpiration', value);
            }
        },
        userCardZipCode: {
            get: function() {
                return this.$store.state.user.card.zipCode;
            },
            set: function(value) {
                this.$store.commit('changeUserCardZipCode', value);
            }
        },
    },
    methods: {
        recaptchaCallback(response) {
            this.googleRecaptcha = response;
        },
        expiredRecaptchaCallback() {
            this.googleRecaptcha = null;
        },
        registerCard: function() {
            this.$refs.formCard.validate().then(cardFormValid => {
                if(cardFormValid) {
                    if(!this.deviceData) {
                        requestBraintreeDeviceData(deviceData => {
                            this.deviceData = deviceData;
                            this.$emit('register-card');
                        }, () => {
                            this.$root.showToast('Something went wrong. Try again in a moment.')
                            this.$refs.creditCardRecaptcha.reset();
                        });
                    } else {
                        this.$emit('register-card');
                    }
                }
            });
        },
        closeDialog: function() {
            this.$refs.creditCardRecaptcha.reset();
            this.googleRecaptcha = null;
            this.$emit('close');
        },
    },
});

const ZIP_CODE_COUNTRIES_REGEXES = {
    CA: [/^[AaBbCcEeGgHhJjKkLlMmNnPpRrSsTtVvXxYy]\d[A-Za-z]\s?\d[A-Za-z]\d$/],
    US: [/^\d{5}(?:-\d{4})?$/],
}

const isValidZipCode = function(value) {
    const countries = Object.keys(ZIP_CODE_COUNTRIES_REGEXES)
    let isValid = false

    countries.forEach((country) => {
        const countryRegexes = ZIP_CODE_COUNTRIES_REGEXES[country]

        if (!countryRegexes) {
            isValid = false
            return
        }

        const match = countryRegexes.find(countryRegex => countryRegex.test(value))
        if(!!match) {
            isValid = true
        }
    })

    return isValid
}

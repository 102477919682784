Vue.component('gs-input-location', {
    props: [
        'addressOrder',
    ],
    data: function() {
        return {
            dialog: false,
            visible: false,
            hasToCarryCargoUpstairs: false,
            elevatorAvailable: false,
            stairFlightCount: 0,
            additionalInfo: '',
            contact: {
                name: undefined,
                phone: undefined
            },
            oldData: {
                hasToCarryCargoUpstairs: false,
                elevatorAvailable: false,
                stairFlightCount: 0,
                additionalInfo: '',
                contact: {
                    name: undefined,
                    phone: undefined
                },
                location: undefined
            }
        };
    },
    template: `
    <v-dialog
            ref="locationDialog"
            :fullscreen="isMobile"
            persistent
            v-model="dialog"
            width="450px">
        <template v-slot:activator="{ on }">
            <v-text-field
                    :append-outer-icon="order > 2 ? 'mdi-close' : ''"
                    v-bind:value="completeAddress"
                    :label="formatNumberWithOrdinalSuffix(order) + ' Location'"
                    readonly
                    dense
                    @focus="on.click"
                    type="text"
                    @click:append-outer="removeLocation"
                    :rules=" [validationRules.required]">
            <template v-slot:append>
                <v-icon v-if="hasToCarryCargoUpstairs && stairFlightCount > 0 && !elevatorAvailable">mdi-stairs
                </v-icon>
                <v-icon v-if="hasToCarryCargoUpstairs && elevatorAvailable">mdi-elevator</v-icon>
                <v-icon v-if="additionalInfo.trim().length > 0">mdi-message-text</v-icon>
            </template>
            </v-text-field>
        </template>
        <v-card>
            <v-toolbar v-if="isMobile" dark color="primary">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-title>{{ !isMobile ? dialogTitle: '' }}</v-card-title>
            <v-card-text>
                <gs-address-auto-complete
                        :preset-address="location.address"
                        :is-visible="dialog"
                        :address-order="location.addressOrder"
                        :preset-place-name="location.placeName"
                        ref="gsAddressAutoComplete"></gs-address-auto-complete>
                <v-checkbox
                        dense
                        v-model="hasToCarryCargoUpstairs"
                        label="Needs to carry cargo up or down elevator or stairs"
                ></v-checkbox>
                <transition name="slide">
                    <div v-if="hasToCarryCargoUpstairs">
                        <v-switch
                                dense
                                v-model="elevatorAvailable"
                                label="Elevator available?"
                                prepend-icon="mdi-elevator"
                                :disabled="!hasToCarryCargoUpstairs"
                        ></v-switch>
                        <v-subheader class="pl-0">
                            <v-icon>mdi-stairs</v-icon>&nbsp;
                            How many flights of stairs are there?
                        </v-subheader>
                        <v-slider
                                v-model="stairFlightCount"
                                :disabled="elevatorAvailable || !hasToCarryCargoUpstairs"
                                thumb-label="always"
                                thumb-size="24"
                                persistent-hint
                                track-color="grey"
                                always-dirty
                                min="1"
                                max="20">
                            <template v-slot:prepend>
                                <v-icon @click="decrementStairFlightCount">
                                    mdi-minus
                                </v-icon>
                            </template>
                            <template v-slot:append>
                                <v-icon @click="incrementStairFlightCount">
                                    mdi-plus
                                </v-icon>
                            </template>
                        </v-slider>
                    </div>
                </transition>
                <v-textarea
                        v-model="additionalInfo"
                        prepend-icon="mdi-message-text"
                        label="Additional information (optional)"
                        placeholder="Describe other details (e. g. parking, contact, building)">
                </v-textarea>
            </v-card-text>
            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" outlined @click="revertLocationDetails">Cancel</v-btn>
                <v-btn color="primary" @click="commitLocationDetails">Select</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    `,
    computed: {
        hasStairs: function() {
            return this.location ? this.location.stairsFlightsCount !== 0 : false;
        },
        hasElevator: function() {
            return this.location ? this.location.hasElevator : false;
        },
        hasAdditionalInfo: function() {
            return this.location ? this.location.additionalInformation.trim() !== '' : '';
        },
        completeAddress: function() {
            return this.location ? this.location.placeName !== '' ? this.location.placeName + ', ' + this.location.address : this.location.address : '';
        },
        addressType: function() {
            return this.location ? this.location.type : '';
        },
        order: function() {
            return this.location.addressOrder;
        },
        dialogTitle: function() {
            return 'Enter address location'
        },
        hasToCarryCargoUpstairsExpansionPanel: {
            get: function() {
                return this.hasToCarryCargoUpstairs ? 0 : undefined;
            },
            set: function(value) {
                this.hasToCarryCargoUpstairs = (value !== undefined);
            }
        },
        location: function() {
            return this.$store.getters.getLocation(this.addressOrder);
        }
    },
    mounted: function() {
        this.setLocationDetails(this.location)
    },
    methods: {
        setLocationDetails(location) {
            this.additionalInfo = this.hasAdditionalInfo ? location.additionalInformation : '';
            this.stairFlightCount = this.hasStairs ? +location.stairsFlightsCount : 0;
            this.elevatorAvailable = this.hasElevator ? location.hasElevator : false;
            this.hasToCarryCargoUpstairs = !!(this.hasElevator || this.hasStairs);
        },
        formatNumberWithOrdinalSuffix: function(number) {
            let s = ["th", "st", "nd", "rd"],
                v = number % 100;
            return number + (s[(v - 20) % 10] || s[v] || s[0]);
        },
        decrementStairFlightCount: function() {
            this.stairFlightCount--;
        },
        incrementStairFlightCount: function() {
            this.stairFlightCount++;
        },
        commitLocationDetails: function() {
            if(this.$refs.gsAddressAutoComplete.validateAddress()) {
                this.dialog = false;
                this.$store.commit('changeProjectLocationDetails', {
                    addressOrder: this.location.addressOrder,
                    details: {
                        needsCarryCargo: this.hasToCarryCargoUpstairs,
                        stairsFlightsCount: this.stairFlightCount,
                        hasElevator: this.elevatorAvailable,
                        additionalInformation: this.additionalInfo,
                        contact: this.contact
                    }
                });
            }
        },
        revertLocationDetails: function() {
            this.$refs.gsAddressAutoComplete.onCancelDialog()
            this.hasToCarryCargoUpstairs = this.oldData.hasToCarryCargoUpstairs;
            this.elevatorAvailable = this.oldData.elevatorAvailable;
            this.stairFlightCount = this.oldData.stairFlightCount;
            this.additionalInfo = this.oldData.additionalInfo;
            this.contact = this.oldData.contact;

            this.$store.commit('changeProjectLocationPlaceDetails', {
                placeName: this.oldData.location.placeName,
                address: this.oldData.location.address,
                coordinates: this.oldData.location.coordinates,
                city: this.oldData.location.city,
                state: this.oldData.location.state,
                country: this.oldData.location.country,
                addressOrder: this.oldData.location.addressOrder,
                addressComponents: this.oldData.location.addressComponents
            });

            this.$store.commit('changeProjectLocationDetails', {
                addressOrder: this.location.addressOrder,
                details: {
                    needsCarryCargo: this.hasToCarryCargoUpstairs,
                    stairsFlightsCount: this.stairFlightCount,
                    hasElevator: this.elevatorAvailable,
                    additionalInformation: this.additionalInfo,
                    contact: this.contact,
                }
            });

            this.dialog = false;
        },
        removeLocation: function() {
            if(this.location.addressOrder > 2) {
                this.$store.commit('removeLocation', this.location.addressOrder);
            }
        }
    },
    watch: {
        dialog: function(show) {
            if(show) {
                this.oldData.hasToCarryCargoUpstairs = this.hasToCarryCargoUpstairs;
                this.oldData.elevatorAvailable = this.elevatorAvailable;
                this.oldData.stairFlightCount = this.stairFlightCount;
                this.oldData.additionalInfo = this.additionalInfo;
                this.oldData.contact = this.contact;
                this.oldData.location = JSON.parse(JSON.stringify(this.location));
            }
        },
        location: {
            handler: function(value) {
                this.setLocationDetails(value)
            },
            immediate: true
        }
    }
});

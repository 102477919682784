Vue.component('gs-no-vehicle-available', {
    template: `
            <div class="info-area col m5 s12">
                <v-icon size="100">mdi-{{ icon }}</v-icon>
                <div class="text-h5 grey--text text--darken-1 font-weight-medium mb-2">{{ title }}</div>
                <p class="grey--text">{{ message }}</p>
            </div>
        `,
    props: {
        icon: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        message: {
            type: String,
            required: false,
            default: ''
        }
    }
});
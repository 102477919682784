Vue.component('gs-reset-password-form', {
    template: `<v-card>
    <validation-observer ref="formResetPassword">
        <v-form
                class="pa-10"
                v-model="valid"
                :lazy-validation="lazy"
                @submit.prevent="resetPassword">

            <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
                <span class="headline">Reset Password</span>
            </v-card-title>

            <gs-v-text-field
                    name="password"
                    vid="newPassword"
                    rules="required|min:8"
                    label="Reset your password"
                    type="password"
                    :disabled="resetPasswordFormSubmitted"
                    v-model.trim="userPassword">
            </gs-v-text-field>

            <gs-v-text-field
                    name="password"
                    rules="required|min:8|confirmed:newPassword"
                    label="Confirm password"
                    type="password"
                    :disabled.sync="resetPasswordFormSubmitted"
                    v-model.trim="userConfirmPassword">
            </gs-v-text-field>

            <vue-recaptcha
                    ref="resetPasswordRecaptcha"
                    @verify="recaptchaCallback"
                    @expired="expiredRecaptchaCallback"
                    size="invisible"
                    :sitekey="GOOGLE_RECAPTCHA_WEBSITE_KEY">
            </vue-recaptcha>

            <v-btn
                block color="primary"
                type="submit"
                @submit="resetPassword"
                :disabled.sync="resetPasswordFormSubmitted">
                Submit
            </v-btn>
        </v-form>
    </validation-observer>
</v-card>
    `,
    data: function() {
        return {
            userConfirmPassword: undefined,
            valid: true,
            lazy: true,
            GOOGLE_RECAPTCHA_WEBSITE_KEY: googleRecaptchaWebsiteKey
        };
    },
    props: ['userData', 'isLoading'],
    components: {
        'vue-recaptcha': VueRecaptcha,
    },
    computed: {
        googleRecaptcha: {
            get() {
                return this.$store.state.resetPasswordGoogleRecaptcha;
            },
            set(val) {
                this.$store.commit('changeResetPasswordGoogleRecaptcha', val);
            }
        },
        userPassword: {
            get: function() {
                return this.$store.state.user.password;
            },
            set: function(value) {
                this.$store.commit('changeUserPassword', value);
            }
        },
        resetPasswordFormSubmitted: {
            get: function() {
                return this.$store.state.resetPasswordFormSubmitted;
            },
            set: function(value) {
                this.$store.commit('setResetPasswordFormSubmitted', value);
            }
        },
    },
    mounted: function() {
        if(this.userData) {
            this.$store.commit('setUser', JSON.parse(this.userData));
        }
    },
    methods: {
        resetPassword: function() {
            this.$refs.formResetPassword.validate().then(result => {
                if (result) {
                    this.$refs.resetPasswordRecaptcha.execute();
                    this.$store.commit('setResetPasswordFormSubmitted', true);
                }
            });
        },
        recaptchaCallback(response) {
            this.googleRecaptcha = response;
            this.$emit('submit-reset-password');
        },
        expiredRecaptchaCallback() {
            this.googleRecaptcha = null;
        }
    },
});

Vue.component('gs-list-time-picker', {
    template: `
        <v-dialog
            v-model="showTimePickerDialog"
            scrollable
            id="list-time-picker-dialog"
            max-width="250px"
            @click:outside="closeDialog"
        >
            <v-card class="gs-list-time-picker">
                <v-card-title>
                  {{ dialogTitle }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-radio-group v-model="selectedTime" class="v-list">
                    <v-radio
                         v-ripple
                         class="v-list-item v-list-item--link theme--light"
                         v-for="n in timeList"
                        :key="n"
                        :label="n"
                        :value="n"
                    ></v-radio>
                    </v-radio-group>
                </v-card-text>
                 <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" outlined @click="closeDialogAndClear">Cancel</v-btn>
                  <v-btn color="primary" text @click="onChooseTime">
                    Ok
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>`,
    props: {
        showTimePickerDialog: {
            type: Boolean,
            default: false
        },
        // TODO: Improve validation when needed.
        min: {
            type: String,
            validator: function(value) {
                return moment(value, 'HH:mm', true).isValid();
            }
        },
        max: {
            type: String,
            validator: function(value) {
                return moment(value, 'HH:mm', true).isValid();
            }
        },
        allowedMinutes: {
            type: Array,
            validator: function(minutes) {
                minutes.forEach((minute) => {
                    if(minute > 59 || minute < 0) {
                        return false;
                    }
                });
                return true;
            }
        }
    },
    data: function() {
        return {
            dialogTitle: 'Select Time',
            selectedTime: undefined
        };
    },
    computed: {
        minTime: function() {
            return moment(this.min, 'HH:mm', true);
        },
        maxTime: function() {
            return moment(this.max, 'HH:mm', true);
        },
        timeList: function() {
            let hoursRange = this.range(this.minTime.hour(), this.maxTime.hour());
            const items = [];
            hoursRange.forEach((hour) => {
                this.allowedMinutesForThisHour(hour).forEach((minute) => {
                    items.push(moment({hour, minute}).format('h:mm A'));
                });
            });

            return items;
        }
    },
    methods: {
        range(a, b) {
            let range = [];
            for(let i = a; i <= b; i++) {
                range.push(i);
            }
            return range;
        },
        allowedMinutesForThisHour(hour) {
            if(this.minTime.hour() === this.maxTime.hour()) {
                return this.allowedMinutes.filter(minutes =>  {
                    return minutes >= this.minTime.minutes() &&
                           minutes <= this.maxTime.minutes();
                });
            }
            if(this.minTime.hour() === hour) {
                return this.allowedMinutes.filter(minutes => minutes >= this.minTime.minutes());
            }
            if(this.maxTime.hour() === hour) {
                return this.allowedMinutes.filter(minutes => minutes <= this.maxTime.minutes());
            }
            return this.allowedMinutes;
        },
        onChooseTime() {
            if(this.selectedTime) {
                this.$emit('on-select-project-time', moment(this.selectedTime, 'hh::mm A').format('HH:mm'));
                this.closeDialog();
            }
        },
        closeDialogAndClear() {
            this.selectedTime = undefined;
            this.closeDialog();
        },
        closeDialog() {
            this.$emit('on-close-dialog');
        },
    },
});

/**
 * Empty states
 * Empty states occur when an item’s content can’t be shown.
 * https://material.io/design/communication/empty-states.html#content
 */
Vue.component('gsEmptyState', {
    template: "<div class=\"secondary--text text-center\">\n" +
        "    <v-icon v-if=\"icon\" class=\"display-4\">mdi-{{ icon }}</v-icon>\n" +
        "    <h1 v-if=\"title\">{{ title }}</h1>\n" +
        "    <p v-if=\"message\">{{ message }}</p>\n" +
        "</div>",
    props: {
        icon: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        message: {
            type: String,
            required: false,
            default: ''
        }
    },

});

Vue.component('gs-address-auto-complete', {
    template: `
      <v-autocomplete
          id="address-auto-complete"
          ref="addressAutoComplete"
          no-data-text="Enter detailed address for suggestions"
          v-model="place"
          :menu-props="{ maxHeight: undefined }"
          no-filter
          :items="predictions"
          item-text="description"
          item-value="description"
          placeholder="Search"
          label="Address"
          return-object
          @change="selectPlace"
          @keyup="searchAddress"
          @click:clear="clearAddress"
          clearable
          :rules="[validationRules.required]">
      <template
          v-slot:item="{ parent, item, on, attrs }">
        <v-list-item
            two-line
            v-on="on"
            v-bind="attrs">
          <v-list-item-content>
            <v-list-item-title
                v-html="highlightPrediction(item.structured_formatting, 'main_text')"></v-list-item-title>
            <v-list-item-subtitle
                class="text--primary"
                v-html="highlightPrediction(item.structured_formatting, 'secondary_text')"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      </v-autocomplete>
    `,
    props: [
        'presetAddress',
        'presetPlaceName',
        'isVisible',
        'addressOrder'
    ],
    data: function() {
        return {
            address: '',
            placeName: '',
            fillLocation: true,
            placeService: undefined,
            autocompleteService: undefined,
            sessionToken: undefined,
            predictions: [],
            place: undefined,
            oldData: {
                predictions: [],
                place: undefined,
                location: undefined
            }
        }
    },
    methods: {
        highlightPrediction: function(predictionStructuredFormatting, textReference) {
            if(!predictionStructuredFormatting) {
                return null
            }

            let text = predictionStructuredFormatting[textReference]
            let matchedSubstring = predictionStructuredFormatting[`${textReference}_matched_substrings`]
            let highlightedText = text

            if(matchedSubstring) {
                matchedSubstring.forEach(function(element) {
                    let offset = element.offset
                    let matchingText = text.substring(offset, offset + element.length).trim()

                    highlightedText = highlightedText.replace(matchingText, "<span class='font-weight-bold'>" + matchingText + "</span>")
                })
            }

            return highlightedText
        },
        initAddressInput: function() {
            const vm = this
            vm.fillCurrentLocation()
            vm.forceAddressInputFocus()
        },
        fillCurrentLocation: function() {
            const vm = this
            if('' === this.presetAddress && vm.addressOrder === 1) {
                if('geolocation' in navigator) {
                    navigator.geolocation.getCurrentPosition(vm.getAddressByPosition, function(error) {
                        vm.location = {
                            error: error.message
                        }
                    }, {
                        enableHighAccuracy: true,
                        timeout: 60000
                    })
                } else {
                    vm.location = {
                        error: 'Geolocation is not supported by this browser'
                    }
                }
            }
        },
        forceAddressInputFocus: function() {
            const vm = this
            if('' === vm.presetAddress) {
                // Chrome focus only works by using setTimeout instead of nextTick
                // Firefox focus works without this and doesn't bother
                setTimeout(() => {
                    vm.$refs.addressAutoComplete.focus()
                }, 200)
            }
        },
        getAddressByPosition: function(geolocationPosition) {
            const vm = this
            if(vm.fillLocation !== true || '' !== this.$store.getters.getLocation(vm.addressOrder).address) {
                return
            }

            vm.location = {
                lat: geolocationPosition.coords.latitude,
                lng: geolocationPosition.coords.longitude
            }
            var geocoder = new google.maps.Geocoder
            geocoder.geocode({'location': vm.location}, function(results, status) {
                if(status === 'OK') {
                    if(results[0]) {
                        vm.commitPlaceDetails(results[0])
                        vm.address = results[0].formatted_address
                        vm.placeName = 'undefined' !== typeof results[0].name ? results[0].name : ''
                        vm.predictions = [{'description': vm.address}]
                        vm.place = vm.predictions[0]
                    } else {
                        vm.location = {
                            error: 'No results found'
                        }
                    }
                } else {
                    vm.location = {
                        error: 'Geocoder failed due to: ' + status
                    }
                }
            })
        },
        clearAddress: function() {
            this.oldData.predictions = this.predictions
            this.oldData.location = this.$store.getters.getLocation(this.addressOrder)
            this.oldData.place = this.place

            this.fillLocation = false
            this.predictions = []
            this.$refs.addressAutoComplete.isMenuActive = false
        },
        onCancelDialog() {
            if(this.oldData.place !== undefined) {
                this.place = this.oldData.place
                this.predictions = this.oldData.predictions
            }
        },
        searchAddress: function(event) {
            const vm = this

            if(event.code == "ArrowUp" || event.code == "ArrowDown") {
                return
            }

            if(!vm.sessionToken) {
                vm.sessionToken = new google.maps.places.AutocompleteSessionToken()
            }
            vm.autocompleteService.getPlacePredictions({
                input: event.target.value,
                types: ['geocode', 'establishment'],
                componentRestrictions: {country: 'us'},
                sessionToken: vm.sessionToken
            }, vm.displaySuggestions)
        },
        displaySuggestions: function(predictions, status) {
            const vm = this

            if(status !== google.maps.places.PlacesServiceStatus.OK) {
                return
            }

            vm.predictions = predictions.filter(function(prediction) {
                return prediction.types.some((type) =>
                    type === 'street_address' ||
                    type === 'premise' ||
                    type === 'point_of_interest' ||
                    type === 'establishment' ||
                    type === 'route' ||
                    type === 'subpremise')
            })
        },
        selectPlace: function() {
            const vm = this

            if(vm.place) {
                let predictionSessionToken = vm.sessionToken
                vm.sessionToken = undefined

                vm.placeService.getDetails({
                    placeId: vm.place.place_id,
                    fields: ['formatted_address', 'geometry', 'name', 'types', 'address_components', 'place_id'],
                    sessionToken: predictionSessionToken
                }, function(place) {
                    vm.commitPlaceDetails(place)
                })
            }
        },
        commitPlaceDetails: function(place) {
            let placeName = ''
            if('undefined' !== typeof place.name &&
                place.types.indexOf('establishment') > -1 &&
                !place.formatted_address.startsWith(place.name)) {
                placeName = place.name
            }

            let coordinates = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            }

            let customerSelectedAddress = place.types.some(type => type === "route") ? this.place.description : undefined

            let placeDetails = {
                placeId: place.place_id,
                placeName: placeName,
                placeTypes: place.types.join(", "),
                address: place.formatted_address,
                coordinates: coordinates,
                addressOrder: this.addressOrder,
                customerSelectedAddress: customerSelectedAddress,
                addressComponents: place.address_components
            }

            this.$store.commit('changeProjectLocationPlaceDetails', placeDetails)

            if(this.addressOrder === 1) {
                this.$store.dispatch('getTimeZone').catch(function() {
                })
            }

        },
        validateAddress: function() {
            return this.$refs.addressAutoComplete.validate()
        }
    },
    watch: {
        isVisible: {
            immediate: true,
            handler(visible) {
                const vm = this
                if(visible === true) {
                    vm.initAddressInput()
                }
            }
        }
    },
    mounted: function() {
        const vm = this
        vm.autocompleteService = new google.maps.places.AutocompleteService()
        vm.placeService = new google.maps.places.PlacesService(document.createElement('div'))

        if(!vm.place && vm.presetAddress !== '') {
            vm.place = {
                'description': `${vm.presetPlaceName}, ${vm.presetAddress}`,
                'structured_formatting': {
                    'main_text': vm.presetPlaceName,
                    'secondary_text': vm.presetAddress
                }
            }
            vm.predictions = [vm.place]
        }

        vm.initAddressInput()
    }
})

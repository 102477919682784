const GOOGLE_ADDRESS_COMPONENTS = {
    state: {
        types: ['administrative_area_level_1'],
        field: 'short_name'
    },
    country: {
        types: ['country'],
        field: 'short_name'
    },
    city: {
        types: [
            'locality',
            'sublocality',
            'neighborhood',
            'postal_town'
        ],
        field: 'long_name'
    },
    county: {
        types: ['administrative_area_level_2'],
        field: 'long_name'
    },
    premise: {
        types: ['premise'],
        field: 'long_name'
    },
    subPremise: {
        types: ['subpremise'],
        field: 'long_name'
    },
    postalCode: {
        types: ['postal_code'],
        field: 'long_name'
    },
    postalCodeSuffix: {
        types: ['postal_code_suffix'],
        field: 'long_name'
    },
};

let getComponentThatHasSomeType = function(addressComponents, types) {
    if(!types || !addressComponents || !Array.isArray(addressComponents)) {
        return null;
    }

    let component = null;
    types.forEach(function(type) {
        if(!component) {
            component = addressComponents.find(function(component) {
                return hasSome(component.types, [type]);
            });
        }
    });

    return component;
};

let extractGoogleAddressComponents = function(addressComponents) {
    return Object.keys(GOOGLE_ADDRESS_COMPONENTS).map(function(key) {
        let addressComponent = GOOGLE_ADDRESS_COMPONENTS[key];
        let res = {};
        let componentWithType = getComponentThatHasSomeType(addressComponents, addressComponent.types);
        res[key] = componentWithType ? componentWithType[addressComponent.field] : null;
        return res;
    }).reduce(function(accumulator, current) {
        return Object.assign(accumulator, current);
    }, {});
};

/**
 * Tells if array1 has some element of array2.
 * @param array1
 * @param array2
 * @returns {*}
 */
let hasSome = function(array1, array2) {
    return array2.some(function(el) {
        return array1.includes(el);
    });
};

Vue.component('gs-sign-register-dialog', {
    template: `
        <v-dialog
            v-model="showSignRegisterDialog"
            id="sign-dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            @click:outside="closeDialog"
            max-width="450px">

            <v-toolbar
                v-if="$vuetify.breakpoint.xsOnly"
                dark
                color="primary"
            >
                <v-btn icon dark @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
            </v-toolbar>

            <gs-sign-in-form
                v-show="showSignInForm"
                @activate-forgot-password-form="activateForgotPasswordForm"
                @activate-sign-up-form="activateSignUpForm"
                @activate-card-form="activateCardForm"
                :user-data="userData"
                :is-loading="isLoading"
                @close="closeDialog"
                @submit-sign-in="signIn"
                ref="signInForm"
            >
            </gs-sign-in-form>

            <gs-forgot-password-form
                v-show="showForgotPasswordForm"
                @activate-sign-in-form="activateSignInForm"
                :user-data="userData"
                :is-loading="isLoading"
                @close="closeDialog"
                ref="forgotPasswordForm"
                @submit-forgot-password="forgotPassword"
            >
            </gs-forgot-password-form>

            <gs-sign-up-form
                v-show="showSignUpForm"
                @activate-sign-in-form="activateSignInForm"
                :user-data="userData"
                ref="signUpForm"
                :is-loading="isLoading"
                @close="closeDialog"
                @submit-sign-up="signUp"
            >
            </gs-sign-up-form>

            <gs-credit-card-form
                v-show="showCardForm"
                :user-data="userData"
                :is-loading="isLoading"
                @close="closeDialog"
                ref="creditCardForm"
                @register-card="updateUserCreditCard"
            >
            </gs-credit-card-form>

        </v-dialog>`,
    props: {
        'userData': String,
        'redirectAfterLogin': {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            isLoading: false,
            isDemo: false
        };
    },
    computed: {
        dialogTitle: {
            get: function() {
                return this.SIGN_DIALOG_TYPES_TEXTS[this.$store.state.activeSignDialog];
            }
        },
        googleRecaptcha: {
            get: function() {
                return this.$store.state.googleRecaptcha;
            },
            set: function(value) {
                this.$store.commit('changeGoogleRecaptcha', value);
            }
        },
        showSignRegisterDialog: {
            get: function() {
                return this.$store.state.showSignRegisterDialog;
            },
            set: function(value) {
                this.$store.commit('setShowSignRegisterDialog', value);
            }
        },
        userEmail: {
            get: function() {
                return this.$store.state.user.email;
            },
            set: function(value) {
                this.$store.commit('changeUserEmail', value);
            }
        },
        showSignInForm: {
            get: function() {
                return this.$store.state.activeSignDialog === this.SIGN_DIALOG_TYPES.SIGN_IN;
            },
        },
        showForgotPasswordForm: {
            get: function() {
                return this.$store.state.activeSignDialog === this.SIGN_DIALOG_TYPES.FORGOT_PASSWORD;
            },
        },
        showSignUpForm: {
            get: function() {
                return this.$store.state.activeSignDialog === this.SIGN_DIALOG_TYPES.SIGN_UP;
            },
        },
        showCardForm: {
            get: function() {
                return this.$store.state.activeSignDialog === this.SIGN_DIALOG_TYPES.CREDIT_CARD;
            },
        },
        CURRENT_STEP: {
            get: function() {
                return this.$store.state.currentStep;
            }
        },
        SIGN_DIALOG_TYPES: function() {
            return {
                SIGN_IN: "SIGN_IN",
                FORGOT_PASSWORD: "FORGOT_PASSWORD",
                SIGN_UP: "SIGN_UP",
                CODE_VALIDATION: "CODE_VALIDATION",
                CREDIT_CARD: "CREDIT_CARD",
            }
        },
        SIGN_DIALOG_TYPES_TEXTS: function() {
            return {
                "SIGN_IN": "Sign In",
                "FORGOT_PASSWORD": "Forgot Password",
                "SIGN_UP": "Sign Up",
                "CREDIT_CARD": "Register Credit Card",
            }
        }
    },
    mounted: function() {
        if(this.userData) {
            this.$store.commit('setUser', JSON.parse(this.userData));
        }
    },
    methods: {
        activateSignInForm: function() {
            this.$store.commit('setActiveSignDialog', this.SIGN_DIALOG_TYPES.SIGN_IN);
        },
        activateForgotPasswordForm: function() {
            this.$store.commit('setActiveSignDialog', this.SIGN_DIALOG_TYPES.FORGOT_PASSWORD);
        },
        activateSignUpForm: function() {
            this.$store.commit('setActiveSignDialog', this.SIGN_DIALOG_TYPES.SIGN_UP);
        },
        activateCardForm: function() {
            this.$store.commit('setActiveSignDialog', this.SIGN_DIALOG_TYPES.CREDIT_CARD);
        },
        deactivateSignDialog: function() {
            this.$store.commit('setActiveSignDialog', null);
        },
        closeDialog: function() {
            this.showSignRegisterDialog = false;
            this.deactivateSignDialog();
        },
        getUserCard: function() {
            this.$store.dispatch('getUserCard')
                .then(() => {
                    this.closeDialog();
                })
                .catch(response => {
                    this.activateCardForm();
                    this.$root.showToast(getErrorMessage(response));
                });
        },
        decideFormToShow: function() {
            if(!this.$store.getters.userSignedIn) {
                this.activateSignInForm();
            } else {
                this.getUserCard();
            }
        },
        signIn(password) {
            this.isLoading = true;
            this.$store.dispatch('signIn', password).then(userData => {
                const isDeliveryPro = userData.user_log_in === 'D';
                this.$store.commit('setUser', userData);
                if(isDeliveryPro || this.redirectAfterLogin) {
                    setTimeout(() => {
                        window.location = '/trucks/dashboard';
                    }, 3000);
                } else {
                    if(!userData.isDemo) {
                        this.getUserCard();
                    } else {
                        this.isDemo = true;
                        this.closeDialog();
                    }
                    this.$store.dispatch('isMultiStopEnabled');

                    if(this.CURRENT_STEP === 5) {
                        this.$root.updateEstimate();
                    }
                }
                this.$root.showToast(`Welcome back, ${userData.user_first_name}.${isDeliveryPro ? ' Delivery Pro accounts can\'t book. Redirecting to the Dashboard in 3s.' : ''}`);
                this.isLoading = false;
            }).catch((message) => {
                this.$refs.signInForm.$refs.signInRecaptcha.reset();
                this.isLoading = false;
                this.$root.showToast(message);
            });
        },
        forgotPassword() {
            this.isLoading = true;
            this.$store.dispatch('forgotPassword').then(responseData => {
                window.location = '/trucks/login';
            }).catch(errorMessage => {
                this.isLoading = false;
                this.$refs.forgotPasswordForm.$refs.forgotPasswordRecaptcha.reset();
                this.$root.showToast(errorMessage);
            });
        },
        signUp() {
            this.isLoading = true;
            this.$store.dispatch('registerCustomerSimple').then(userId => {
                this.isLoading = false;
                this.$root.showToast('Successfully registered.');
                this.$store.commit('changeUserId', userId);
                this.closeDialog();
            }).catch(errorMessage => {
                this.$refs.signUpForm.$refs.signUpRecaptcha.reset();
                this.isLoading = false;

                if(errorMessage === 'Someone is already registered with this email address.') {
                    this.activateSignInForm();
                }
                this.$root.showToast(errorMessage);
            });
        },
        updateUserCreditCard: function() {
            this.isLoading = true;
            const vm = this;
            vm.$store.dispatch('updateUserCard')
                .then(responseData => {
                    vm.isLoading = false;
                    vm.$store.commit('setUserCardOnFile', true);
                    vm.$root.showToast('Card registered. Proceed to book.');
                    vm.closeDialog();
                })
                .catch(function(errorMessage) {
                    vm.$refs.creditCardForm.$refs.creditCardRecaptcha.reset();
                    vm.isLoading = false;
                    if(errorMessage === 'Declined. Contact your bank.') {
                        vm.closeDialog();
                        vm.$store.commit('setErrorDialog', {
                            show: true,
                            title: 'Credit card declined',
                            content: 'Check your card details and try again. If the decline persists, insert another card or contact us.'
                        });
                    }
                    vm.$root.showToast(errorMessage);
                });
        },
    },
    watch: {
        showSignRegisterDialog: function(newVal, oldVal) {
            if(newVal !== oldVal && newVal === true) {
                this.decideFormToShow();
            }
        }
    }
});

Vue.component('gs-v-text-field', {
    template: `<validation-provider
                 :name="name"
                 :rules="rules"
                 :vid="vid"
                 ref="validationProvider"
                 v-slot="{ errors }">
                    <v-text-field
                     :id="id"
                     ref="vTextField"
                     v-model.sync="innerValue"
                     v-bind="$attrs"
                     v-on="$listeners"
                     @input.native=""
                     @change.native=""
                     :error-messages="errors">
                    </v-text-field>
                </validation-provider>`,
    props: {
        rules: String,
        name: String,
        value: {
            type: [String, Number],
            required: false,
            default: ''
        },
        mask: {
            type: Object,
            required: false,
            default: undefined
        },
        unmaskValue: {
            type: Boolean,
            required: false,
            default: true
        },
        vid: {
            type: String,
            required: false
        },
        id: {
            type: String,
            required: false
        },
        multipleMasks: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: function() {
        return {
            innerValue: this.value
        };
    },
    watch: {
        value: function(newVal) {
            this.innerValue = newVal;
        }
    },
    mounted: function() {
        if(this.mask) {
            let options = this.mask;
            options.onValueChanged = this.onValueChanged.bind(this);
            this._vCleave = new Cleave(this.$refs.vTextField.$refs.input, options);
            this._vCleave.setRawValue(this.value);
        }
        if(this.multipleMasks) {
            this.initMultipleMasksForZipCode()
        }
    },
    methods: {
        onValueChanged: function({target}) {
            const vm = this;
            if(this._vCleave) {
                this.$nextTick(function() {
                    this.innerValue = target.value;
                    vm.$emit('input', (vm.unmaskValue ? target.rawValue : target.value));
                });
            }
        },
        initMultipleMasksForZipCode() {
            const vm = this
            $('#' + this.id).on('keyup', function() {
                const input = $(this).val()
                if(vm._vCleave) {
                    vm._vCleave.destroy()
                }
                if(input.length > 0 && isNaN(parseInt(input[0]))) {
                    vm._vCleave = new Cleave(vm.$refs.vTextField.$refs.input, masks.zipCode.CA);
                } else if(input.length > 0) {
                    vm._vCleave = new Cleave(vm.$refs.vTextField.$refs.input, masks.zipCode.US);
                }
            })
        }
    },
    $_veeValidate: {
        value: function() {
            return this.mask ? (
                this.unmaskValue ?
                    this._vCleave.getRawValue() :
                    this._vCleave.getFormattedValue()
            ) : this.innerValue;
        }
    }
});

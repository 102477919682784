GSAnalyticsVue = {
    install(Vue, options) {

        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-KW7KD3');

        gtag('js', new Date());
        gtag('config', 'UA-55640342-1');
        gtag('config', 'AW-968535233');

        const firebaseConfig = {
            apiKey: "AIzaSyBKx5ruxTCq1rMX8B4DUAKjp8O5feNj-ak",
            authDomain: "goshare-driver.firebaseapp.com",
            databaseURL: "https://goshare-driver.firebaseio.com",
            projectId: "goshare-driver",
            storageBucket: "goshare-driver.appspot.com",
            messagingSenderId: "877770027733",
            appId: "1:877770027733:web:2fab9373beef47fd74285c",
            measurementId: "G-4GPDDQCMMJ"
        };

        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
        firebase.analytics().setUserProperties({environment: Vue.config.devtools ? "debug" : "release"}, {global: true});

        Vue.prototype.$analytics = {
            logEvent(analyticsEvent, callback) {
                if(analyticsEvent.hasOwnProperty('firebase') && analyticsEvent.firebase !== null) {
                    firebase.analytics().logEvent(analyticsEvent.firebase.event, analyticsEvent.firebase.data)
                }

                if(analyticsEvent.hasOwnProperty('analytics') && analyticsEvent.analytics !== null) {
                    if(analyticsEvent.environment === 'release') {
                        gtag('event', 'page_view', {
                            "page_location": window.location.origin,
                            "page_path": analyticsEvent.analytics.step,
                            "page_title": analyticsEvent.analytics.title,
                            "send_to": "UA-55640342-1"
                        });
                    } else {
                        console.info('Analytics event', 'send', 'pageview', analyticsEvent.analytics.step, analyticsEvent.analytics.title);
                        if(typeof callback === "function") {
                            callback.call();
                        }
                    }
                }
            },
            config(config) {
                firebase.analytics().setUserProperties(config);
            }
        }
    }
}

Vue.use(GSAnalyticsVue)
Vue.component('gs-price-breakdown', {
    template: "<div>\n" +
        "    <p class=\"caption grey--text d-block mb-0\">Price breakdown</p>\n" +
        "    <v-skeleton-loader type=\"list-item@3\" v-if=\"loading || !estimate\"></v-skeleton-loader>\n" +
        "    <template v-else>\n" +
        "        <div v-if=\"!hasUpfrontPrice\">\n" +
        "            <v-row>\n" +
        "                <v-col cols=\"6\" class=\"py-0\"><span>Base fee</span></v-col>\n" +
        "                <v-col cols=\"6\" class=\"py-0 text-right\"><span>\n" +
        "                       {{ formatValue(estimate.base_fee) }}</span>\n" +
        "                </v-col>\n" +
        "            </v-row>\n" +
        "            <v-row v-bind:class=\"{'before-divider': !hasEquipment}\">\n" +
        "                <v-col cols=\"6\" class=\"py-0\"><span>+ Per minute</span></v-col>\n" +
        "                <v-col cols=\"6\" class=\"py-0 text-right\"><span>\n" +
        "                       {{ formatValue(estimate.price_per_minute) }}</span>\n" +
        "                </v-col>\n" +
        "            </v-row>\n" +
        "            <v-row v-if=\"hasEquipment\" class=\"before-divider\">\n" +
        "                <v-col cols=\"6\" class=\"py-0\"><span>Additional equipment</span></v-col>\n" +
        "                <v-col cols=\"6\" class=\"py-0 text-right\"><span>\n" +
        "                       {{ formatValue(estimate.equipment) }}</span>\n" +
        "                </v-col>\n" +
        "            </v-row>\n" +
        "            <v-row class=\"after-divider\">\n" +
        "                <v-col cols=\"6\" class=\"pb-0\"><span>Minimum price</span></v-col>\n" +
        "                <v-col cols=\"6\" class=\"pb-0 text-right\">\n" +
        "                    <span id=\"minimum-price\">{{ formatValue(estimate.minimum_price) }}</span>\n" +
        "                </v-col>\n" +
        "            </v-row>\n" +
        "            <v-row>\n" +
        "                <v-col cols=\"6\" class=\"pt-0\"><span>Estimated price</span></v-col>\n" +
        "                <v-col cols=\"6\" class=\"pt-0 text-right\">\n" +
        "                    <span v-if=\"hasDiscount\"\n" +
        "                          id=\"estimate-price\"\n" +
        "                          class=\"price-breakdown-primary-price-text\">{{ estimate.estimated_price_interval }}</span>\n" +
        "                    <span v-else>\n" +
        "                        <span class=\"lined small-text text-no-wrap\">{{ estimate.estimated_price_interval }}</span><br>\n" +
        "                        <span class=\"text-no-wrap\">{{ estimate.discounted_prices.discounted_price_interval }}</span>\n" +
        "                    </span>\n" +
        "                </v-col>\n" +
        "            </v-row>\n" +
        "        </div>\n" +
        "        <div v-else>\n" +
        "             <v-row v-if=\"hasHelper\" v-for=\"(vehiclePrice, i) of estimate.vehicle_price_list\" class=\"before-divider\" :key=\"i\">\n" +
        "                 <v-col cols=\"6\" class=\"py-0\"><span class=\"gs-label\">{{(i == 0) ? \"Driver\": \"Helper\"}}</span>\n" +
        "                 </v-col>\n" +
        "                 <v-col cols=\"6\" class=\"py-0 text-right\"><span class=\"gs-label\">{{formatValue(vehiclePrice.upfront_base_price + vehiclePrice.base_fee + vehiclePrice.cargo_additional_fee_total)}}</span></v-col>\n" +
        "             </v-row>\n" +
        "             <v-row v-if=\"hasEquipment\" class=\"before-divider\">\n" +
        "                 <v-col cols=\"6\" class=\"py-0\"><span class=\"gs-label\">Additional equipment</span></v-col>\n" +
        "                 <v-col cols=\"6\" class=\"py-0 text-right\"><span class=\"gs-label\">{{ formatValue(estimate.equipment) }}</span>\n" +
        "                 </v-col>\n" +
        "             </v-row>\n" +
        "            <div v-if=\"hasHelper || hasEquipment\" class=\"price-breakdown-divider\"></div>\n" +
        "            <v-row class=\"after-divider\">\n" +
        "                <v-col cols=\"6\" class=\"pt-0\"><span>Project price</span></v-col>\n" +
        "                <v-col cols=\"6\" class=\"pt-0 text-right\">\n" +
        "                    <span v-if=\"hasDiscount\"\n" +
        "                          id=\"estimate-price\"\n" +
        "                          class=\"price-breakdown-primary-price-text\">{{ formatValue(estimate.upfront_price) }}</span>\n" +
        "                    <span v-else><span\n" +
        "                            class=\"lined small-text text-no-wrap\">{{ formatValue(estimate.upfront_price) }}</span><br><span\n" +
        "                            class=\"text-no-wrap\">{{ formatValue(estimate.discounted_upfront_prices.upfront_price) }}</span></span>\n" +
        "                </v-col>\n" +
        "            </v-row>\n" +
        "        </div>\n" +
        "\n" +
        "        <p v-if=\"!isOfferModel && hasUpfrontPrice && isOvertimeAllowed\"\n" +
        "           class=\"grey--text body-2\">\n" +
        "           <span v-if=\"!hasDifferentEstimateDuration\">\n" +
        "                • {{ formatValue(estimate.price_per_minute) }}/min will be added to the Project price if Project exceeds {{ maxEstimatedDuration }} minutes.\n" +
        "           </span v-else>\n" +
        "           <span v-else>\n" +
        "                • Project price will be increased by {{ formatValue(estimate.vehicle_price_list[0].price_per_minute) }} per minute if Driver exceeds {{ parseInt(estimate.vehicle_price_list[0].upfront_estimated_duration.max) }} minutes, and by {{ formatValue(estimate.vehicle_price_list[1].price_per_minute) }} per minute if Helper exceeds {{ parseInt(estimate.vehicle_price_list[1].upfront_estimated_duration.max) }} minutes.\n" +
        "           </span>\n" +
        "        </p>\n" +
        "    </template>\n" +
        "</div>",
    props: [
        'estimate',
        'loading'
    ],
    computed: {
        hasUpfrontPrice: function() {
            return !!this.estimate.upfront_price;
        },
        isOvertimeAllowed: function() {
            return !!this.estimate.isOvertimeAllowed;
        },
        hasDifferentEstimateDuration: function() {
            let vehiclePriceList = this.estimate.vehicle_price_list;
            return vehiclePriceList.length > 1 ?
                (vehiclePriceList[0].upfront_estimated_duration.max !== vehiclePriceList[1].upfront_estimated_duration.max) : false;
        },
        isOfferModel: function() {
            return this.estimate.pricing_model === "OFFER";
        },
        upfrontEstimatedDuration: function() {
            const estimatedDuration = this.estimate.upfront_estimated_duration;
            if(!this.hasUpfrontPrice || !estimatedDuration) {
                return null;
            }
            return parseInt(estimatedDuration.min) + (estimatedDuration.min !== estimatedDuration.max ? (" - " + parseInt(estimatedDuration.max)) : "");
        },
        maxEstimatedDuration: function() {
            return parseInt(this.estimate.upfront_estimated_duration.max);
        },
        hasDiscount: function() {
            return !this.estimate.discount.status;
        },
        hasEquipment: function() {
            return !!this.estimate.equipment;
        },
        hasHelper: function() {
            return this.estimate.vehicle_price_list.length > 1;
        }
    },
    methods: {
        formatValue: function(value) {
            return "$" + parseFloat(value).toFixed(2)
        }
    }
});

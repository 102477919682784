Array.prototype.unique = function() {
    let a = this.concat()
    for(let i = 0; i < a.length; ++i) {
        for(let j = i + 1; j < a.length; ++j) {
            if(a[i] === a[j]) {
                a.splice(j--, 1)
            }
        }
    }

    return a
}

Array.prototype.diff = function(arr2) {
    return this.filter(x => !arr2.includes(x))
}

Array.prototype.naturalLanguageJoin = function() {
    let a = this.concat()
    return a.concat(a.splice(-2, 2).join(' and ')).join(', ')
}

Array.prototype.groupBy = function(f) {
    return this.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {})
}

/* Classes */
function Route() {
    this.distance = null
    this.duration = null

    this.setDistance = function(distance) {
        this.distance = distance
    }

    this.setDuration = function(duration) {
        this.duration = duration
    }

    this.clean = function() {
        this.distance = null
        this.duration = null
    }

    this.isSet = function() {
        return this.distance !== null && this.duration !== null
    }

    this.toJson = function() {
        return {
            distance: this.distance,
            duration: this.duration
        }
    }
}

function RouteLeg(distance, duration, startAddress, endAddress) {
    this.distance = distance
    this.duration = duration
    this.startAddress = startAddress
    this.endAddress = endAddress

    this.setDistance = function(distance) {
        this.distance = distance
    }

    this.setDuration = function(duration) {
        this.duration = duration
    }

    this.clean = function() {
        this.distance = null
        this.duration = null
        this.startAddress = null
        this.endAddress = null
    }

    this.isSet = function() {
        return this.distance !== null && this.duration !== null && this.startAddress !== null && this.endAddress !== null
    }
}

const formatEstimatedTravelDuration = function(minutes) {
    minutes = parseInt(minutes)

    if(minutes <= 0) {
        return '0 min'
    }

    var duration = luxon.Duration.fromObject({minutes: minutes}).shiftTo('hours', 'minutes').toObject()
    var formattedTextArray = []

    if(duration.hours > 0) {
        formattedTextArray.push(duration.hours + ' hr')
    }
    if(duration.minutes > 0) {
        formattedTextArray.push(duration.minutes + ' min')
    }

    return formattedTextArray.join(' ')
}

const formatTimeRemainingDuration = function(minutes) {
    minutes = parseInt(minutes)

    if(minutes <= 0) {
        return '0 min'
    }

    var duration = luxon.Duration.fromObject({minutes: minutes}).shiftTo('days', 'hours', 'minutes').toObject()
    var formattedTextArray = []

    if(duration.days > 0) {
        formattedTextArray.push(duration.days + ' d')
    }
    if(duration.hours > 0) {
        formattedTextArray.push(duration.hours + ' hr')
    }
    if(duration.minutes > 0) {
        formattedTextArray.push(duration.minutes + ' min')
    }

    return formattedTextArray.join(' ')
}

const formatEstimatedTravelDistance = function(distance) {
    return parseFloat(distance).toFixed(1) + ' mi'
}

const formatEstimatedTravelDurationAndDistance = function(minutes, distance) {
    return formatEstimatedTravelDuration(minutes) + ' (' + formatEstimatedTravelDistance(distance) + ')'
}

Vue.prototype.$formatEstimatedTravelDurationAndDistance = formatEstimatedTravelDurationAndDistance

const getDriverAndHelperDurationEstimateRanges = function(subProjects) {
    let durationEstimateRanges = {}
    if(subProjects === undefined) {
        return durationEstimateRanges
    }

    subProjects.forEach(subProject => {
        if(!subProject.upfront_estimated_duration) {
            return
        }

        if(!durationEstimateRanges['driver']) {
            durationEstimateRanges['driver'] = `${subProject.upfront_estimated_duration.min} - ${subProject.upfront_estimated_duration.max} min`
        } else if(!durationEstimateRanges['helper']) {
            durationEstimateRanges['helper'] = `${subProject.upfront_estimated_duration.min} - ${subProject.upfront_estimated_duration.max} min`
        }
    })

    return durationEstimateRanges
}


const getDriverAndHelperEarnings = function(subProjects) {
    let earnings = {}
    if(!subProjects) {
        return earnings
    }

    subProjects.forEach(subProject => {
        let value = subProject.goshare_final_payment_amount > 0 ? formatCurrencyDollars(subProject.goshare_final_payment_amount) : subProject.driver_estimated_earnings
        if(!earnings['driver']) {
            earnings['driver'] = value
        } else if(!earnings['helper']) {
            earnings['helper'] = value
        }
    })

    return earnings
}

/**
 * Prevents `func` from being called twice within a `delay` milliseconds interval.
 */
const once = (func, delay) => {
    let timerId

    return (...args) => {
        if(!timerId) {
            func(...args)
        }

        timerId = setTimeout(() => {
            timerId = null
        }, delay)
    }
}

const formatDateToZonedDate = ({dateTime, fromFormat, toFormat, timezone}) => {
    if(!dateTime) {
        return '-'
    }

    return (fromFormat ?
        luxon.DateTime.fromFormat(dateTime, fromFormat, {zone: timezone || 'America/Los_Angeles'}) :
        luxon.DateTime.fromISO(dateTime, {zone: timezone}))
        .toFormat(toFormat || "LLL dd, yyyy 'at' hh:mm a (ZZZZ)")
}

Vue.prototype.$formatDateToZonedDate = formatDateToZonedDate

function formatCurrencyCents(amountInCents = 0, keepDigits = false) {
    const options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    }

    if(amountInCents % 100 === 0 && !keepDigits) {
        options.minimumFractionDigits = 0
    }

    const formatter = Intl.NumberFormat('en-US', options)
    return formatter.format(amountInCents * 0.01)
}

function formatPhoneNumber(phoneNumber) {
    if(typeof (phoneNumber) !== 'string') {
        return phoneNumber
    }
    if(/[^0-9]/.test(phoneNumber)) {
        return phoneNumber
    }
    if(phoneNumber.length === 11 && !phoneNumber.startsWith('1')) {
        return phoneNumber
    }
    if(phoneNumber.length < 10 || phoneNumber.length > 11) {
        return phoneNumber
    }

    return phoneNumber.length === 10
        ? phoneNumber.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, '($1) $2-$3')
        : phoneNumber.replace(/1([0-9]{3})([0-9]{3})([0-9]{4})/, '+1 ($1) $2-$3')
}

Vue.prototype.formatPhoneNumber = formatPhoneNumber

function formatCurrencyDollars(amountInDollars = 0) {
    return formatCurrencyCents(amountInDollars * 100)
}

function formatCurrencyCentsWith2Digits(amountInCents) {
    return formatCurrencyCents(amountInCents, true)
}

Vue.prototype.formatCurrencyCents = formatCurrencyCents
Vue.prototype.formatCurrencyDollars = formatCurrencyDollars
Vue.prototype.formatCurrencyCentsWith2Digits = formatCurrencyCentsWith2Digits


function jsonSyntaxHighlight(json) {
    if(!json) {
        return `<span>{}</span>`
    }

    json = JSON.stringify(json, undefined, 2)
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
    const regex = /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g
    return json.replace("<pre></pre>", "").replace(regex, (match) => {
        let color = 'fuchsia'
        if(/^"/.test(match)) {
            if(/:$/.test(match)) {
                color = 'brown'
            } else {
                color = 'olive'
            }
        } else if(/true|false/.test(match)) {
            color = 'navy'
        } else if(/null/.test(match)) {
            color = 'magenta'
        }
        return `<span style=\"color:${color}\">${match}</span>`
    })
}

Vue.prototype.jsonSyntaxHighlight = jsonSyntaxHighlight

var environmentMeta = document.getElementsByName('environment');
var environment = environmentMeta.length > 0 ? environmentMeta[0].content : 'development';

if(environment === undefined || environment === null) {
    console.error('Sentry environment was not set correctly.')
}

if(environment !== 'local') {
    Sentry.init({
        dsn: 'https://95ebee9f3033444786d162446dda2b25@sentry.goshare.co/10',
        integrations: [new Sentry.Integrations.Vue({Vue, attachProps: true})],
        environment: environment,
    });
}

Vue.component('gs-forgot-password-form', {
    template: `
            <v-card>
                <v-form
                    class="pa-10"
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    @submit.prevent="forgotPassword"
                >
                    <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
                        <span class="headline">Forgot Password?</span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                                label="Email"
                                v-model="userEmail"
                                :rules="emailRules"
                                required
                        ></v-text-field>
                        <a
                            class="overline"
                            v-show="validationMessage"
                            target="_blank"
                            style="text-decoration: none;"
                            >{{ validationMessage }}
                        </a>
                    </v-card-text>

                    <vue-recaptcha
                            ref="forgotPasswordRecaptcha"
                            @verify="recaptchaCallback"
                            @expired="expiredRecaptchaCallback"
                            size="invisible"
                            :sitekey="GOOGLE_RECAPTCHA_WEBSITE_KEY">
                    </vue-recaptcha>

                    <v-btn block color="primary" type="submit" @submit="forgotPassword" :loading="isLoading">Submit</v-btn>
                </v-form>
            </v-card>
    `,
    data: function() {
        return {
            valid: true,
            lazy: true,
            validationMessage:'',
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            GOOGLE_RECAPTCHA_WEBSITE_KEY: googleRecaptchaWebsiteKey
        };
    },
    props: ['userData', 'isLoading'],
    components: {
        'vue-recaptcha': VueRecaptcha,
    },
    computed: {
        userEmail: {
            get: function() {
                return this.$store.state.user.email;
            },
            set: function(value) {
                this.$store.commit('changeUserEmail', value);
            }
        },
        googleRecaptcha: {
            get() {
                return this.$store.state.forgotPasswordGoogleRecaptcha;
            },
            set(val) {
                this.$store.commit('changeForgotPasswordGoogleRecaptcha', val);
            }
        }
    },
    mounted: function() {
        if(this.userData) {
            this.$store.commit('setUser', JSON.parse(this.userData));
        }
    },
    methods: {
        forgotPassword: function() {
            if(!this.$refs.form.validate()) {
                return;
            }
            this.$refs.forgotPasswordRecaptcha.execute();
        },
        signIn: function() {
            this.$emit('activate-sign-in-form');
        },
        closeDialog: function() {
            this.$emit('close');
        },
        recaptchaCallback(response) {
            this.googleRecaptcha = response;
            this.$emit('submit-forgot-password');
        },
        expiredRecaptchaCallback() {
            this.googleRecaptcha = null;
        }
    },
});

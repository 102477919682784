let disclaimer = [
    {"OFFER,UPFRONT,PER_MINUTE": "Your Project includes $40,000 in cargo insurance protection."},
    {"OFFER,UPFRONT,PER_MINUTE": "Your card will be temporarily authorized for ${holdAmount}. Your statement will reflect the final charge within 7 business days of Project completion."},
    {"OFFER,UPFRONT": "Your initial estimate is based on the estimated time it takes to move each item you entered from the pickup address to the drop-off address that you provided."},
    {"PER_MINUTE": "Your final price is calculated based on a ${baseFee} base fee plus ${perMinuteRate} per minute."},
    {"OFFER,UPFRONT,PER_MINUTE": "Your initial estimate does not include any road tolls or dumping fees. Any road tolls or dumping fees incurred during the Project will be added to your final price."},
    {"OFFER": "In the state of California, contractors using GoShare have the option to set their own price by making a counteroffer. Accepting one of these offers may allow you to book your Project faster. If you pass, we will continue to search for Delivery Pros at the initial price estimate."},
    {
        "UPFRONT": "If the total time it takes to complete your Project exceeds ${maxEstimatedDuration} minutes, an overtime charge of ${perMinuteRate} per minute will be added to the Project price.",
        isOvertimeAllowed: true,
        hasDifferentEstimateDuration: false
    },
    {
        "UPFRONT": "Project price will be increased by ${perMinuteRateDriver} per minute if Driver exceeds ${maxEstimatedDurationDriver} minutes, and by ${perMinuteRateHelper} per minute if Helper exceeds ${maxEstimatedDurationDriver} minutes.",
        isOvertimeAllowed: true,
        hasDifferentEstimateDuration: true
    },
    {"UPFRONT,PER_MINUTE": "Your Project begins when Delivery Pro(s) begins loading and ends when they finish unloading."},
    {"OFFER,UPFRONT,PER_MINUTE": "A Delivery Pro has the right to decline a Project at the pickup address if they are unable or unwilling to provide service due to inaccurate Project details including cargo items. In this case, you will be charged a cancellation fee."},
    {"UPFRONT,PER_MINUTE": "Pricing will vary if the items or addresses entered into GoShare are not accurate."},
    {"OFFER,UPFRONT,PER_MINUTE": "Assembly is for furniture only. GoShare doesn't currently support appliance installation or assembly for cribs, gazebos, outdoor play structures, bunk beds, hot tubs, home gyms, or pool tables."},
];

function replaceStringLiterals(row, replacementList) {
    return row.split(/(\${\w+?})/g).map(function(v) {
        let replaced = v.replace(/\${(\w+?)}/g, '$1');
        return replacementList[replaced] || replaced;
    }).join("");
}

function getDisclaimerList(pricingModel, isOvertimeAllowed, replacementList, hasDifferentEstimateDuration) {
    let result = [];
    disclaimer.forEach(replaceRow);

    function replaceRow(row) {
        var rowArray = Object.entries(row);

        if(rowArray[0][0].includes(pricingModel)
            && (row.isOvertimeAllowed == null || row.isOvertimeAllowed === isOvertimeAllowed)
            && (row.hasDifferentEstimateDuration == null || row.hasDifferentEstimateDuration === hasDifferentEstimateDuration)) {
            result.push(replaceStringLiterals(rowArray[0][1], replacementList));
        }

    }

    return result;
}

Vue.component('gs-booking-disclaimer', {
    template: `
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <a
          class="text-decoration-underline"
          dark
          href="#"
          v-bind="attrs"
          v-on="on"
          @click.prevent
        >
          pricing policy
        </a>
      </template>

      <v-card>
        <v-card-title>
          Pricing Policy
        </v-card-title>

        <v-card-text>
            <ul>
               <li v-for="row in getDisclaimerList">
                   {{ row }}
                </li>
           </ul>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>`,
    props: [
        'estimate',
        'loading'
    ],
    data: function() {
        return {
            dialog: false
        };
    },
    computed: {
        hasUpfrontPrice: function() {
            return !!this.estimate.upfront_price;
        },
        hasDifferentEstimateDuration: function() {
            let vehiclePriceList = this.estimate.vehicle_price_list;
            return vehiclePriceList.length > 1 && this.hasUpfrontPrice ?
                (vehiclePriceList[0].upfront_estimated_duration.max !== vehiclePriceList[1].upfront_estimated_duration.max) : false;
        },
        isOfferModel: function() {
            return this.estimate.pricing_model === "OFFER";
        },
        getDisclaimerList: function() {
            if('undefined' === typeof this.estimate) {
                return [];
            }

            let vehiclePriceList = this.estimate.vehicle_price_list;
            let replacementList = [];
            replacementList['baseFee'] = this.formatValue(this.estimate.base_fee);
            replacementList['perMinuteRate'] = this.perMinuteRate;
            replacementList['maxEstimatedDuration'] = this.maxEstimatedDuration;
            replacementList['holdAmount'] = this.formatValue(this.estimate.authorization_hold_amount);

            if(vehiclePriceList.length > 1 && this.hasUpfrontPrice) {
                replacementList['maxEstimatedDurationDriver'] = parseInt(vehiclePriceList[0].upfront_estimated_duration.max)
                replacementList['perMinuteRateDriver'] = this.formatValue(vehiclePriceList[0].price_per_minute);
                replacementList['maxEstimatedDurationHelper'] = parseInt(vehiclePriceList[1].upfront_estimated_duration.max)
                replacementList['perMinuteRateHelper'] = this.formatValue(vehiclePriceList[1].price_per_minute);
            }

            return getDisclaimerList(this.estimate.pricing_model, this.estimate.isOvertimeAllowed, replacementList, this.hasDifferentEstimateDuration)
        },
        upfrontEstimatedDuration: function() {
            const estimatedDuration = this.estimate.upfront_estimated_duration;
            if(!this.hasUpfrontPrice || !estimatedDuration) {
                return null;
            }
            return parseInt(estimatedDuration.min) + (estimatedDuration.min !== estimatedDuration.max ? (" - " + parseInt(estimatedDuration.max)) : "");
        },
        maxEstimatedDuration: function() {
            if(typeof this.estimate.upfront_estimated_duration !== 'undefined') {
                return parseInt(this.estimate.upfront_estimated_duration.max);
            }
            return 0;
        },
        perMinuteRate: function() {
            return this.formatValue(this.estimate.price_per_minute * this.$store.state.project.priceMultiplier.multiplier);
        }
    },
    methods: {
        formatValue: function(value) {
            return "$" + parseFloat(value).toFixed(2).replace(/\.0+$/, '')
        }
    }
});

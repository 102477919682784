Vue.component('gs-booking-cancellation-disclaimer', {
    template: `
    <v-dialog
      v-model="dialog"
      width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <a
          class="text-decoration-underline"
          dark
          v-bind="attrs"
          v-on="on"
          href="#"
          @click.prevent
        >
          cancellation policy
        </a>
      </template>

      <v-card>
        <v-card-title>
          {{ title }}
        </v-card-title>

        <v-card-text>
            {{ message }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>`,
    data: function() {
        return {
            dialog: false,
            title: '',
            message: ''
        };
    },
    mounted: function() {
        let vm = this;
        this.$store.dispatch('getCancellationDisclaimer')
            .then(function(success) {
                vm.title = success.title
                vm.message = success.message
            })
            .catch(function(error) {
                vm.$store.commit('setToast');
            });
    },
 });

Vue.component('gs-sign-in-form', {
    template: `
            <v-card>
                <v-form
                    class="pa-10"
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    @submit.prevent="signIn"
                >
                    <v-card-title v-if="!$vuetify.breakpoint.xsOnly">
                        <span class="headline">Sign In</span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                                label="Email"
                                v-model="userEmail"
                                :rules="emailRules"
                                required
                        ></v-text-field>
                        <v-text-field
                                label="Password"
                                v-model="password"
                                :rules="passwordRules"
                                type="password"
                                required
                        ></v-text-field>
                        <a
                            class="overline"
                            @click="forgotPassword"
                            target="_blank"
                            style="text-decoration: none;"
                            >Forgot password?
                        </a>
                    </v-card-text>
                    <vue-recaptcha
                            ref="signInRecaptcha"
                            @verify="recaptchaCallback"
                            @expired="expiredRecaptchaCallback"
                            size="invisible"
                            :sitekey="GOOGLE_RECAPTCHA_WEBSITE_KEY">
                    </vue-recaptcha>

                    <v-btn block color="primary" type="submit" @submit="signIn" :loading="isLoading">Sign In</v-btn>

                    <p class="text--secondary pt-4">
                        <v-btn @click="signUp" text color="primary">Create a Customer account</v-btn><br>
                        <v-btn href="/trucks/delivery-professional/create-account" text color="primary">Create a Delivery Pro account</v-btn>
                    </p>
                </v-form>
            </v-card>
    `,
    data: function() {
        return {
            valid: true,
            lazy: true,
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            password: '',
            passwordRules: [
                v => !!v || 'Password is required',
            ],
            GOOGLE_RECAPTCHA_WEBSITE_KEY: googleRecaptchaWebsiteKey
        };
    },
    props: ['userData', 'isLoading'],
    components: {
        'vue-recaptcha': VueRecaptcha,
    },
    computed: {
        userEmail: {
            get: function() {
                return this.$store.state.user.email;
            },
            set: function(value) {
                this.$store.commit('changeUserEmail', value);
            }
        },
        googleRecaptcha: {
            get() {
                return this.$store.state.signInGoogleRecaptcha;
            },
            set(val) {
                this.$store.commit('changeSignInGoogleRecaptcha', val);
            }
        }
    },
    mounted: function() {
        if(this.userData) {
            this.$store.commit('setUser', JSON.parse(this.userData));
            this.getUserCard();
        }
    },
    methods: {
        signIn: function() {
            if(!this.$refs.form.validate()) {
                return;
            }
            this.$refs.signInRecaptcha.execute();
        },
        forgotPassword: function(){
            this.$emit('activate-forgot-password-form');
        },
        signUp: function() {
            this.$emit('activate-sign-up-form');
        },
        registerCard: function() {
            this.$emit('activate-card-form');
        },
        closeDialog: function() {
            this.$emit('close');
        },
        getUserCard: function() {
            const vm = this;
            this.$store.dispatch('getUserCard')
            .then(function(success) {
                vm.closeDialog();
            })
            .catch(function(response) {
                vm.$root.showToast(getErrorMessage(response));
                vm.registerCard();
            });
        },
        recaptchaCallback(response) {
            this.googleRecaptcha = response;
            this.$emit('submit-sign-in', this.password);
        },
        expiredRecaptchaCallback() {
            this.googleRecaptcha = null;
        }
    },
});

Vue.component('gs-nav-profile-info', {
        template: `
        <v-menu
            v-if="isAnyUserSignedIn"
            v-model="showMenu"
            offset-y
            transition="slide-x-transition"
            bottom
            right>
          <template v-slot:activator="{ on, attrs }">
            <div v-ripple class="profile-container" v-bind="attrs"
            v-on="on">
            <v-avatar>
                <v-img :src="getUserUrlImage(userinfo.photo)"
                     alt="profile picture"></v-img>
            </v-avatar>
            <v-subheader v-if="!isMobile" class="pr-0 no-select-text">Hi, {{userinfo.firstName}}</v-subheader>
            </div>
          </template>
          <v-card max-width="200"
            class="mx-auto">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <div class="overline text-center mb-1">
                      <v-avatar size="90">
                      <v-img :src="getUserUrlImage(userinfo.photo)"
                          alt="profile picture"></v-img>
                      </v-avatar>
                  </div>
                  <div class="overline text-center mb-1">{{userinfo.firstName}} {{userinfo.lastName}}</div>
                  <v-list-item-subtitle>{{userinfo.email}}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{userinfo.phone}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn href="/trucks/logout/" text color="primary">
                Sign out
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-menu>
        `,
        props: [
            'userinfo',
            'userSignedId'
        ],
        data: () => {
            return {
                showMenu: false
            };
        },
        computed: {
            isAnyUserSignedIn: function() {
                return !!this.userSignedId;
            },
        },
        methods: {
            getUserUrlImage: function(photo) {
                return (photo) ? photo : "https://static.goshare.co/placeholders/user_avatar.png";
            }
        }
    });


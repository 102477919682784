function TimeSlot(hours, minutes, amPm) {
    this.hours = hours;
    this.minutes = minutes;
    this.amPm = amPm || null;

    this.getHours = function() {
        return this.hours;
    };

    this.getMinutes = function() {
        return this.minutes;
    };

    this.compareToDate = function(date) {
        return this.getTotalMinutes() - (TimeSlot.fromDate(date).getTotalMinutes());
    };

    this.compareToMomentDate = function(date) {
        return this.getTotalMinutes() - (TimeSlot.fromMomentDate(date).getTotalMinutes());
    };

    this.getTotalMinutes = function() {
        return this.hours * 60 + this.minutes;
    };

    this.toArray = function() {
        if(this.amPm) {
            return [this.hours, this.minutes, this.amPm];
        }
        return [this.hours, this.minutes];
    };

    this.toString = function() {
        return this.hours + ':' + this.minutes;
    };

}

var getMinBookingDateTime = function(TIME_PICKER_MINUTES_INTERVAL,
                                     DEFAULT_MIN_BOOKING_TIME_SLOT,
                                     DEFAULT_MAX_BOOKING_TIME_SLOT,
                                     currentDate /* moment object */) {
    if(!currentDate) {
        return undefined;
    }

    var nextBookingInterval = 30;
    var minDateTime = currentDate;
    var millisecondsUntilNextAvailableTimeSlot = (
        (nextBookingInterval - minDateTime.minute() % nextBookingInterval)
        % nextBookingInterval + TIME_PICKER_MINUTES_INTERVAL
    ) * 60 * 1000;

    minDateTime = moment(minDateTime.valueOf() + millisecondsUntilNextAvailableTimeSlot).tz(currentDate.tz());

    if(DEFAULT_MIN_BOOKING_TIME_SLOT.compareToMomentDate(minDateTime) > 0 ||
       DEFAULT_MAX_BOOKING_TIME_SLOT.compareToMomentDate(minDateTime) < 0) {

        if(DEFAULT_MAX_BOOKING_TIME_SLOT.compareToMomentDate(minDateTime) < 0) {
            minDateTime.date(minDateTime.date() + 1);
        }
        minDateTime.hours(DEFAULT_MIN_BOOKING_TIME_SLOT.getHours());
        minDateTime.minutes(DEFAULT_MIN_BOOKING_TIME_SLOT.getMinutes());
    }
    minDateTime.seconds(0);
    minDateTime.milliseconds(0);

    return minDateTime;
};

TimeSlot.fromDate = function(date) {
    return new TimeSlot(date.getHours(), date.getMinutes());
};

TimeSlot.fromMomentDate = function(date) {
    return new TimeSlot(date.hours(), date.minutes());
};

/**
 * Expects a moment object with timezone, and
 * says if the date is today in that timezone.
 * @param date: Moment
 * @returns {boolean}
 */
var isDateToday = function(date) {
    if(!date) {
        throw new Error('Date is required');
    }

    const today = moment().tz(date.tz());
    return date &&
           date.date() === today.date() &&
           date.month() === today.month() &&
           date.year() === today.year();
};

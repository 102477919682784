/* Constants */
const PROJECT_TYPE_ONE_DELIVERY_PRO_ONE_TRUCK = 1
const PROJECT_TYPE_TWO_DELIVERY_PROS_ONE_TRUCK = 3

function GsAnalyticsEvent(firebaseEvent = null, googleAnalyticsEvent = null) {
    this.environment = Vue.config.devtools ? 'debug' : 'release'
    this.analytics = googleAnalyticsEvent
    this.firebase = firebaseEvent
}

/* Vuex Store */

const store = new Vuex.Store({
    state: {
        currentStep: 1,
        tab: null,
        loader: false,
        minBookingDateTime: moment(),
        categoryIndex: 0,
        categoriesEnabled: false,
        categories: [{alias: 'move'}],
        availableEquipment: [],
        possibleCargoItemWeightList: [],
        availableVehicleTypeList: [],
        deviceData: null,
        signInGoogleRecaptcha: null,
        forgotPasswordGoogleRecaptcha: null,
        resetPasswordGoogleRecaptcha: null,
        signUpGoogleRecaptcha: null,
        creditCardGoogleRecaptcha: null,
        toast: {
            show: false,
            content: '',
        },
        errorDialog: {
            title: '',
            content: '',
            callback: undefined
        },
        user: {
            id: undefined,
            password: undefined,
            userType: undefined,
            firstName: undefined,
            lastName: undefined,
            businessName: undefined,
            email: undefined,
            phone: undefined,
            photo: undefined,
            coupon: {},
            country: 'US',
            zipCode: undefined,
            state: undefined,
            cities: [],
            city: undefined,
            tos: false,
            isDemo: false,
            card: {
                onFile: false,
                number: '',
                nameOnCard: '',
                expirationDate: undefined,
                cvv: undefined,
                zipCode: undefined,
            }
        },
        multiStopEnabled: false,
        clonedFromProjectId: null,
        project: {
            projectType: undefined,
            vehicleType: undefined,
            date: '',
            time: '',
            locations: [],
            firstLocationTimeZone: moment.tz.guess(),
            promoCode: '',
            route: new Route(),
            estimate: undefined,
            upfrontPriceAvailable: false,
            pricingModel: 'PER_MINUTE',
            zoneId: undefined,
            serviceAvailable: undefined,
            selectedEquipment: [],
            cargoItems: [],
            cargoAdditionalInfo: '',
            images: [],
            priceMultiplier: {
                multiplier: 1,
                end: null
            }
        },
        activeSignDialog: null,
        showSignRegisterDialog: false,
        resetPasswordFormSubmitted: false,
        successfullyBooked: false
    },
    mutations: {
        setShowSignRegisterDialog: function(state, payload) {
            state.showSignRegisterDialog = payload
        },
        setActiveSignDialog: function(state, payload) {
            state.activeSignDialog = payload
        },
        setResetPasswordFormSubmitted: function(state, payload) {
            state.resetPasswordFormSubmitted = payload
        },
        setErrorDialog: function(state, payload) {
            state.errorDialog = payload
        },
        setFirstLocationTimeZone: function(state, payload) {
            state.project.firstLocationTimeZone = payload
        },
        setCategories: function(state, payload) {
            state.categories = payload
        },
        changeCategoriesEnabled: function(state, payload) {
            state.categoriesEnabled = payload
        },
        changeMinBookingDateTime: function(state, payload) {
            state.minBookingDateTime = payload
        },
        changeLoader: function(state, show) {
            state.loader = show
        },
        changeStep: function(state, toStep) {
            state.currentStep = toStep
        },
        changeTab: function(state, tab) {
            state.tab = tab
        },
        changeCategory: function(state, toCategory) {
            state.categoryIndex = toCategory
        },
        changeDeviceData: function(state, deviceData) {
            state.deviceData = deviceData
        },
        changeSignInGoogleRecaptcha: function(state, googleRecaptcha) {
            state.signInGoogleRecaptcha = googleRecaptcha
        },
        changeForgotPasswordGoogleRecaptcha: function(state, googleRecaptcha) {
            state.forgotPasswordGoogleRecaptcha = googleRecaptcha
        },
        changeResetPasswordGoogleRecaptcha: function(state, googleRecaptcha) {
            state.resetPasswordGoogleRecaptcha = googleRecaptcha
        },
        changeSignUpGoogleRecaptcha: function(state, googleRecaptcha) {
            state.signUpGoogleRecaptcha = googleRecaptcha
        },
        changeCreditCardGoogleRecaptcha: function(state, googleRecaptcha) {
            state.creditCardGoogleRecaptcha = googleRecaptcha
        },
        changeProjectType: function(state, projectTypeId) {
            state.project.projectType = projectTypeId
        },
        changeProjectDate: function(state, date) {
            state.project.date = date
        },
        changeProjectTime: function(state, time) {
            state.project.time = time
        },
        changeProjectSelectedEquipment: function(state, selectedEquipment) {
            state.project.selectedEquipment = selectedEquipment
        },
        clearProjectLocationDetails: function(state, addressOrder) {
            let location = this.getters.getLocation(addressOrder)
            location.coordinates.lat = undefined
            location.coordinates.lng = undefined
            location.address = ''
            location.placeName = ''
            location.placeId = undefined
            location.placeTypes = undefined
            location.addressComponents = undefined
            location.needsCarryCargo = false
            location.stairsFlightsCount = 0
            location.hasElevator = false
            location.additionalInformation = ''
            location.customerSelectedAddress = undefined
            location.contact = null
            location.country = ''
            location.state = ''
            location.city = ''

            Vue.set(state.project.locations, location.index, location)
        },
        removeLocation: function(state, addressOrder) {
            let location = this.getters.getLocation(addressOrder)
            Vue.delete(state.project.locations, location.index)

            for(var j = location.index; j < state.project.locations.length; j++) {
                state.project.locations[j].addressOrder = j
                Vue.set(state.project.locations[j], 'addressOrder', addressOrder++)
            }

            if(state.project.cargoItems.length > 0) {
                for(var i = 0; i < state.project.cargoItems.length; i++) {
                    if(state.project.cargoItems[i].unloadAddressOrder === addressOrder) {
                        state.project.cargoItems[i].unloadAddressOrder = null

                    } else if(state.project.cargoItems[i].loadAddressOrder === addressOrder) {
                        state.project.cargoItems[i].loadAddressOrder = null
                    }
                }
            }
        },
        moveLocation: function(state, addressOrders) {
            let addressOrderTo = addressOrders.addressOrderTo
            if(addressOrderTo && addressOrders.addressOrderFrom < addressOrders.addressOrderTo) {
                addressOrderTo--
            }

            let locationFrom = this.getters.getLocation(addressOrders.addressOrderFrom)
            let locationTo = addressOrderTo ? this.getters.getLocation(addressOrderTo) : null

            state.project.locations.splice(locationFrom.index, 1);
            if(locationTo) {
                state.project.locations.splice(locationTo.index, 0, locationFrom);
            } else {
                state.project.locations.push(locationFrom);
            }

            for(var i = 0; i < state.project.locations.length; i++) {
                state.project.locations[i].index = i
                state.project.locations[i].addressOrder = i + 1
                Vue.set(state.project.locations[i], 'addressOrder', i + 1)
            }
        },
        changeProjectLocationPlaceDetails: function(state, placeDetails) {
            let location = this.getters.getLocation(placeDetails.addressOrder)
            location.coordinates.lat = placeDetails.coordinates.lat
            location.coordinates.lng = placeDetails.coordinates.lng
            location.address = placeDetails.address
            location.placeName = placeDetails.placeName
            location.placeId = placeDetails.placeId
            location.placeTypes = placeDetails.placeTypes
            location.customerSelectedAddress = placeDetails.customerSelectedAddress
            location.addressComponents = placeDetails.addressComponents

            Vue.set(state.project.locations, location.index, location)
        },
        changeProjectLocationDetails: function(state, locationDetails) {
            let location = this.getters.getLocation(locationDetails.addressOrder)
            location.needsCarryCargo = locationDetails.details.needsCarryCargo
            location.stairsFlightsCount = locationDetails.details.stairsFlightsCount
            location.hasElevator = locationDetails.details.hasElevator
            location.additionalInformation = locationDetails.details.additionalInformation
            location.contact = locationDetails.details.contact

            Vue.set(state.project.locations, location.index, location)
        },
        changeProjectVehicleType: function(state, vehicleType) {
            state.project.vehicleType = vehicleType
        },
        changeProjectPromoCode: function(state, promoCode) {
            state.project.promoCode = promoCode
        },
        changeProjectPricingModel: function(state, pricingModel) {
            state.project.pricingModel = pricingModel
        },
        changeZoneInfo: function(state, serviceInfo) {
            state.project.zoneId = serviceInfo.zoneId
            state.project.serviceAvailable = serviceInfo.serviceAvailable
            state.project.upfrontPriceAvailable = serviceInfo.upfrontPriceAvailable
        },
        changeProjectEstimate: function(state, estimate) {
            state.project.estimate = estimate
        },
        changeRoute: function(state, route) {
            state.project.route = route
        },
        changeProjectCargoAdditionalInfo: function(state, additionalInfo) {
            state.project.cargoAdditionalInfo = additionalInfo
        },
        addCargoItem: function(state, item) {
            let found = false
            state.project.cargoItems.forEach(function(value, index, arr) {
                if((value.item.id && value.item.id === item.item.id &&
                        (value.loadAddressOrder === item.loadAddressOrder && value.unloadAddressOrder === item.unloadAddressOrder)) ||
                    (value.item.name === item.item.name && value.estimatedWeight === item.estimatedWeight &&
                        (value.loadAddressOrder === item.loadAddressOrder && value.unloadAddressOrder === item.unloadAddressOrder))) {
                    value.quantity += item.quantity
                    found = true
                }
            })

            if(!found) {
                state.project.cargoItems.push(item)
            }
        },
        updateCargoItem: function(state, item) {
            let index = state.project.cargoItems.indexOf(item)
            if(index !== -1) {
                state.project.cargoItems[index] = item
            }
        },
        removeCargoItem: function(state, itemIndex) {
            state.project.cargoItems.splice(itemIndex, 1)
        },
        addProjectImage: function(state, image) {
            state.project.images.push(image)
        },
        removeProjectImage: function(state, itemIndex) {
            state.project.images.splice(itemIndex, 1)
        },
        setAvailableEquipment: function(state, availableEquipmentList) {
            state.availableEquipment = availableEquipmentList
        },
        setAvailableVehicleTypes: function(state, vehicleTypesList) {
            state.availableVehicleTypeList = vehicleTypesList
        },
        setPossibleCargoItemWeightList: function(state, possibleCargoItemWeightList) {
            state.possibleCargoItemWeightList = possibleCargoItemWeightList
        },
        setMultiStopEnabled: function(state, multiStopEnabled) {
            state.multiStopEnabled = multiStopEnabled
        },
        setClonedFromProjectId: function(state, clonedFromProjectId) {
            state.clonedFromProjectId = clonedFromProjectId
        },
        setToast: function(state, message) {
            state.toast.show = true
            state.toast.content = message
        },
        changeUserId: function(state, id) {
            state.user.id = +id
        },
        changeUserFirstName: function(state, name) {
            state.user.firstName = name
        },
        changeUserLastName: function(state, name) {
            state.user.lastName = name
        },
        changeUserBusinessName: function(state, name) {
            state.user.businessName = name
        },
        changeUserPassword: function(state, password) {
            state.user.password = password
        },
        changeUserPhone: function(state, phone) {
            state.user.phone = phone
        },
        changeUserCityState: function(state, cityState) {
            if(cityState.cities && cityState.cities.length === 1) {
                state.user.city = cityState.cities[0]
            }
            state.user.cities = cityState.cities || []
            state.user.state = cityState.state
        },
        changeUserEmail: function(state, email) {
            state.user.email = email
        },
        changeIsDemo: function(state, isDemo) {
            state.user.isDemo = isDemo
        },
        changeUserZipCode: function(state, zipCode) {
            state.user.zipCode = zipCode
        },
        changeUserCountry: function(state, country) {
            state.user.country = country
        },
        changeUserCardName: function(state, name) {
            state.user.card.nameOnCard = name
        },
        changeUserCardNumber: function(state, number) {
            state.user.card.number = number
        },
        changeUserCardCvv: function(state, cvv) {
            state.user.card.cvv = cvv
        },
        changeUserCardExpiration: function(state, expiration) {
            state.user.card.expirationDate = expiration
        },
        changeUserCardZipCode: function(state, zipCode) {
            state.user.card.zipCode = zipCode
        },
        changeUserTos: function(state, tos) {
            state.user.tos = tos
        },
        setProjectPriceMultiplier: function(state, multiplier) {
            state.project.priceMultiplier.multiplier = multiplier.multiplier
            state.project.priceMultiplier.end = multiplier.end
        },
        setUser: function(state, userData) {
            state.user.id = +userData.user_id
            state.user.userType = userData.user_log_in || userData.user_type
            state.user.email = userData.user_email || userData.email
            state.user.firstName = userData.user_first_name || userData.name
            state.user.lastName = userData.user_last_name || userData.lastName
            state.user.photo = userData.user_photo || userData.photo
            state.user.phone = userData.user_mobile || userData.phone
            state.user.isDemo = (/true/).test(userData.isDemo)
            if(userData.cupon) {
                state.user.coupon = {
                    code: userData.coupon.coupon,
                    category: userData.coupon.category,
                    redemptionReward: userData.coupon.redemption_reward,
                    branchLink: userData.coupon.branch_link,
                }
            }
        },
        setUserCardOnFile: function(state, cardOnFile) {
            state.user.card.onFile = cardOnFile
        },
        setSuccessfullyBooked: function(state) {
            state.successfullyBooked = true
        }
    },
    actions: {
        getCategories: function(context) {
            axios.get('/trucks/category').then(function(response) {
                context.commit('changeCategoriesEnabled', response.data.enabled)
                context.commit('setCategories', response.data.categories)
                if(!response.data.enabled) {
                    context.commit('changeCategory', 0)
                }
            })
        },
        updateMinBookingDateTime: function(context, args) {
            let minDate = getMinBookingDateTime(
                args.TIME_PICKER_MINUTES_INTERVAL,
                args.DEFAULT_MIN_BOOKING_TIME_SLOT,
                args.DEFAULT_MAX_BOOKING_TIME_SLOT,
                context.getters.currentDateTime
            )
            context.commit('changeMinBookingDateTime', minDate)
        },
        getAvailableEquipment: function(context) {
            return new Promise(function(resolve, reject) {
                axios.get('/trucks/equipment/available_equipment' + (context.state.project.zoneId ? ('/' + context.state.project.zoneId) : '')).then(function(response) {
                    if(Array.isArray(response.data.available_equipment)) {
                        let availableEquipment = response.data.available_equipment.map(function(equipment) {
                            return {
                                id: +equipment.equipment_id,
                                alias: equipment.alias,
                                name: equipment.name,
                                price: +equipment.price
                            }
                        })
                        context.commit('setAvailableEquipment', availableEquipment)
                        resolve(availableEquipment)
                    }
                })
            })
        },
        getPossibleCargoItemWeightList: function(context) {
            axios.get('/trucks/cargo_item/weight_list').then(function(response) {
                if(Array.isArray(response.data.cargo_weight_list)) {
                    let possibleCargoItemWeightList = response.data.cargo_weight_list.map(function(cargoWeight) {
                        return {
                            value: cargoWeight.cargo_weight_id,
                            text: cargoWeight.weight
                        }
                    })
                    context.commit('setPossibleCargoItemWeightList', possibleCargoItemWeightList)
                }
            })
        },
        isMultiStopEnabled: function(context) {
            axios.get('/trucks/features/MULTI_STOP/is-enabled').then(function(response) {
                if(response.data.status === true) {
                    context.commit('setMultiStopEnabled', true)
                }
            })
        },
        getAvailableVehicleTypes: function(context) {
            return new Promise(function(resolve, reject) {
                axios.post('/trucks/vehicle_types/get_all_displayable_vehicle', {
                    project_type_id: (context.state.project.projectType || PROJECT_TYPE_ONE_DELIVERY_PRO_ONE_TRUCK),
                    price_multiplier: context.state.project.priceMultiplier.multiplier,
                    project_address_list: JSON.stringify(context.getters.projectAddressList),
                    project_cargo_item_list: JSON.stringify(context.getters.projectCargoList)
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(function(response) {
                    if(response.data.status) {
                        context.commit('setAvailableVehicleTypes', response.data.vehicle_types)

                        if(context.state.project.vehicleType === undefined) {
                            context.commit('changeProjectVehicleType', response.data.vehicle_types[0].vehicle_type_id)
                        } else {
                            context.commit('changeProjectVehicleType', context.state.project.vehicleType)
                            context.commit('changeTab', 'tab-' + context.state.project.vehicleType)
                        }

                        resolve()
                    } else {
                        context.commit('setAvailableVehicleTypes', [])
                        reject()
                    }
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        getCancellationDisclaimer: function(context) {
            let helper = context.state.project.projectType === PROJECT_TYPE_TWO_DELIVERY_PROS_ONE_TRUCK ? [15] : []
            let driver = [context.state.project.vehicleType]
            let vehicleTypeIdList = driver.concat(helper)

            return new Promise(function(resolve, reject) {
                axios.post('/trucks/projects/cancellation-disclaimer', {
                    vehicleTypeIdList: JSON.stringify(vehicleTypeIdList),
                    coordinateList: JSON.stringify(context.getters.coordinateList),
                }).then(function(response) {
                    if(response.data.status) {
                        resolve(response.data.data)
                    } else {
                        reject(response)
                    }
                }).catch(function(errorResponse) {
                    reject(errorResponse)
                })
            })
        },
        getRouteFromGoogle: function(context) {
            const directionsService = new google.maps.DirectionsService()

            let addressStack = context.state.project.locations.slice(0)
            let firstAddress = addressStack.shift()
            let lastAddress = addressStack.pop()
            let departureTime = moment.tz(context.state.project.date + ' ' + context.state.project.time, context.state.project.firstLocationTimeZone).toDate()

            const request = {
                origin: {
                    lat: firstAddress.coordinates.lat,
                    lng: firstAddress.coordinates.lng,
                },
                destination: {
                    lat: lastAddress.coordinates.lat,
                    lng: lastAddress.coordinates.lng,
                },
                travelMode: google.maps.DirectionsTravelMode.DRIVING,
                drivingOptions: {
                    departureTime: departureTime,
                    trafficModel: google.maps.TrafficModel.BEST_GUESS
                },
                unitSystem: google.maps.UnitSystem.IMPERIAL
            }

            // Includes points on middle of route, case they exists.
            if(addressStack.length >= 1) {
                let waypoints = []
                addressStack.forEach(function(address) {
                    waypoints.push({
                        location: new google.maps.LatLng(address.coordinates.lat, address.coordinates.lng),
                        stopover: true
                    })
                })

                request.waypoints = waypoints
            }

            return new Promise(function(resolve, reject) {
                directionsService.route(request, function(response, status) {
                    let errorMessage = ""
                    switch(status) {
                        case google.maps.DirectionsStatus.OK:
                            let distance = 0
                            let duration = 0
                            let legs = []

                            // Sum the distance and duration from all legs
                            response.routes[0].legs.forEach(function(leg) {
                                let routeLegDuration = leg.duration_in_traffic ? leg.duration_in_traffic : leg.duration
                                distance += leg.distance.value * 0.000621371192237 // always in meters, convert to miles
                                duration += routeLegDuration.value / 60 // to minutes
                                legs.push(new RouteLeg(leg.distance.value, routeLegDuration.value, leg.start_address, leg.end_address))
                            })

                            let bookingRoute = new Route()
                            bookingRoute.setDistance(distance)
                            bookingRoute.setDuration(duration)
                            context.commit('changeRoute', bookingRoute)
                            resolve(legs)
                            break
                        case google.maps.DirectionsStatus.NOT_FOUND:
                        case google.maps.DirectionsStatus.ZERO_RESULTS:
                            errorMessage = 'No route could be traced between the given locations.'
                            break
                        case google.maps.DirectionsStatus.MAX_WAYPOINTS_EXCEEDED:
                        case google.maps.DirectionsStatus.MAX_ROUTE_LENGTH_EXCEEDED:
                            errorMessage = 'No route could be traced between the given locations and waypoints.'
                            break
                        default:
                            errorMessage = 'No route could be traced. Try again later.'
                    }
                    if(errorMessage) {
                        context.commit('setToast', errorMessage)

                        var directionsErrorReport = JSON.parse(JSON.stringify(response))
                        directionsErrorReport.originalRequest = JSON.parse(JSON.stringify(request))
                        try {
                            directionsErrorReport.dateTimeFormatOptions = Intl.DateTimeFormat().resolvedOptions()
                        } catch(e) {
                        }
                        /*
                          TODO sentry crash report
                         */
                        reject()
                    }
                })
            })
        },
        hasService: function(context) {
            return new Promise(function(resolve, reject) {
                context.dispatch('getRouteFromGoogle').then(function(legs) {
                    axios.post('/trucks/zone/has_service', {
                        distance: parseInt(context.state.project.route.distance),
                        project_address_list: JSON.stringify(context.getters.projectAddressList),
                        legs: JSON.stringify(legs)
                    }).then(function(response) {
                        if(Array.isArray(response.data.pricing_model_list)) {
                            if(response.data.pricing_model_list.includes("OFFER")) {
                                context.commit('changeProjectPricingModel', "OFFER")
                            } else if(response.data.pricing_model_list.includes("UPFRONT")) {
                                context.commit('changeProjectPricingModel', "UPFRONT")
                            }
                        }
                        context.commit('changeZoneInfo', {
                            zoneId: response.data.zone_id,
                            serviceAvailable: {
                                status: response.data.status,
                                errorCode: response.data.error_code
                            },
                            upfrontPriceAvailable: Array.isArray(response.data.pricing_model_list) && response.data.pricing_model_list.includes("UPFRONT")
                        })

                        if(!response.data.status) {
                            context.commit('setToast', response.data.message)
                        }
                        resolve()
                    }).catch(function(response) {
                        reject()
                    })
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        getTimeZone: function(context) {
            return new Promise(function(resolve, reject) {
                axios.get('/trucks/zone/timezone', {
                    params: {
                        latitude: context.state.project.locations[0].coordinates.lat,
                        longitude: context.state.project.locations[0].coordinates.lng,
                    }
                }).then(function(response) {
                    if(response.data.status) {
                        context.commit('setFirstLocationTimeZone', response.data.data.timezone)
                        context.dispatch('updateMinBookingDateTime', {
                            TIME_PICKER_MINUTES_INTERVAL: 30,
                            DEFAULT_MIN_BOOKING_TIME_SLOT: new TimeSlot(5, 0, 'AM'),
                            DEFAULT_MAX_BOOKING_TIME_SLOT: new TimeSlot(23, 30),
                        })
                        resolve()
                    } else {
                        reject()
                    }
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        getPriceMultiplier: function(context) {
            return new Promise(function(resolve, reject) {
                axios.post('/trucks/pricing/get_multiplier', {
                    booking_date: context.getters.projectDateTimeString,
                    project_address_list: JSON.stringify(context.getters.projectAddressList)
                }).then(function(response) {
                    if(response.data.status) {
                        context.commit('setProjectPriceMultiplier', {
                            multiplier: parseFloat(response.data.price_multiplier),
                            end: response.data.time_stop
                        })
                        resolve()
                    } else {
                        reject()
                    }
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        signIn: function(context, password) {
            return new Promise(function(resolve, reject) {
                axios.post('/trucks/user/login_web', {
                    email: context.state.user.email,
                    password: password,
                    app_type: 'A',
                    cameFromBooking: 1,
                    google_recaptcha: context.state.signInGoogleRecaptcha
                }).then(function(response) {
                    if(response.data.status) {
                        const userData = response.data.results
                        resolve(userData)
                    } else {
                        reject(response.data.message)
                    }
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        forgotPassword: function(context) {
            return new Promise(function(resolve, reject) {
                axios.post('/trucks/recover_password', {
                    email: context.state.user.email,
                    google_recaptcha: context.state.forgotPasswordGoogleRecaptcha
                }).then(function(response) {
                    if(!response.data.status) {
                        reject(response.data.message)
                    }
                    resolve()
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        resetPassword: function(context) {
            return new Promise(function(resolve, reject) {
                axios.post('/trucks/reset_password/', {
                    password: context.state.user.password,
                    google_recaptcha: context.state.resetPasswordGoogleRecaptcha
                }).then(function(response) {
                    if(!response.data.status) {
                        reject(response.data.message)
                    }
                    context.commit('setResetPasswordFormSubmitted', true)
                    resolve(response.data.message)
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        getEstimate: function(context) {
            return new Promise(function(resolve, reject) {
                axios.post('/trucks/job/estimated_price', context.getters.projectEstimateRequestData).then(function(response) {
                    if(response.data.status) {
                        context.commit('changeProjectEstimate', response.data)
                        resolve()
                    } else {
                        reject()
                    }
                }).catch(function(error) {
                    reject()
                })
            })
        },
        getItemsWithRequiredEquipments: function(context, args) {
            const itemIds = args.items.map(function(item) {
                return item.item_id
            })

            const projectAddressIdMappedByOrder = args.addresses.map(function(address) {
                return {
                    projectAddressId: +address.project_address_id,
                    order: +address.address_order
                }
            })
            return new Promise(function(resolve, reject) {
                axios.get('/trucks/cargo-items/required-equipments', {
                    params: {
                        itemIds: JSON.stringify(itemIds)
                    }
                }).then(function(response) {
                    if(response.status) {
                        args.items.forEach(function(item) {
                            let requiredEquipments = response.data.data.find(function(elem) {
                                return parseInt(item.item_id) === elem.itemId
                            })

                            const requiredEquipment = (requiredEquipments !== undefined && requiredEquipments.hasOwnProperty('requiredEquipments')) ? requiredEquipments.requiredEquipments : [];

                            context.commit('addCargoItem', {
                                item: {
                                    id: item.item_id,
                                    name: item.item_name,
                                    requiredEquipmentList: requiredEquipment
                                },
                                quantity: +item.quantity,
                                estimatedWeight: (item.weight) ? item.cargo_weight_id : null,
                                loadAddressOrder: projectAddressIdMappedByOrder.find(elem => elem.projectAddressId === +item.pickup_project_address_id).order,
                                unloadAddressOrder: projectAddressIdMappedByOrder.find(elem => elem.projectAddressId === +item.dropoff_project_address_id).order
                            })
                        })
                        resolve()
                    } else {
                        reject('Error fetching required equipment')
                    }
                }).catch(function(errorResponse) {
                    reject(errorResponse)
                })
            })
        },
        fetchCloneProjectInfo: function(context) {
            if((context.state.clonedFromProjectId == null) || (context.state.user.id == null)) {
                return
            }

            return new Promise(function(resolve, reject) {
                axios.post('/trucks/customer/project_details', {
                    customer_id: context.state.user.id,
                    job_id: context.state.clonedFromProjectId,
                }).then(function(response) {
                    if(response.data.status) {
                        let project = response.data.data[0]

                        if(project.project_address_list.every(address => address.place_id !== null)) {

                            project.project_address_list.forEach(function(address) {
                                context.dispatch('addLocation')

                                context.commit('changeProjectLocationPlaceDetails', {
                                    placeName: address.place_name,
                                    address: address.address,
                                    coordinates: {
                                        lat: parseFloat(address.coordinates.split(",")[0]),
                                        lng: parseFloat(address.coordinates.split(",")[1])
                                    },
                                    placeId: address.place_id,
                                    placeTypes: address.place_types,
                                    addressOrder: +address.address_order,
                                    addressComponents: {
                                        fromGoogle: false,
                                        city: address.city,
                                        state: address.state,
                                        country: address.country,
                                        county: address.county,
                                        postalCode: address.postal_code,
                                        postalCodeSuffix: address.postal_code_suffix,
                                        subPremise: address.sub_premise
                                    }
                                })
                                context.commit('changeProjectLocationDetails', {
                                    addressOrder: +address.address_order,
                                    details: {
                                        needsCarryCargo: !!(+address.stairs_flights_count > 0 || +address.has_elevator),
                                        stairsFlightsCount: +address.stairs_flights_count,
                                        hasElevator: !!+address.has_elevator,
                                        additionalInformation: address.additional_information,
                                    }
                                })

                                if(+address.address_order === 1) {
                                    context.dispatch('getTimeZone').catch(function(tzErr) {
                                        console.error(tzErr)
                                    })
                                }
                            })

                            context.dispatch('getItemsWithRequiredEquipments', {
                                items: project.job_cargo_items,
                                addresses: project.project_address_list
                            }).catch(function(err) {
                                console.error(err)
                            })

                            context.commit('changeProjectVehicleType', +project.job_driver_helper_list[0].vehicle_id)
                            context.commit('changeProjectType', +project.job_driver_helper_list.length > 1 ? PROJECT_TYPE_TWO_DELIVERY_PROS_ONE_TRUCK : PROJECT_TYPE_ONE_DELIVERY_PRO_ONE_TRUCK)

                            let equipmentIdList = project.job_driver_helper_list[0].equipment_list.map(function(equipment) {
                                return +equipment.equipment_id
                            })

                            context.commit('changeProjectSelectedEquipment', equipmentIdList)

                            resolve()
                        } else {
                            reject("The Project you're trying to reorder requires the addresses to be input again")
                        }
                    } else {
                        reject("It was not possible to reorder the requested Project")
                    }
                }).catch(function(error) {
                    reject("An error occurred")
                })
            })
        },
        addLocation: function(context) {
            context.state.project.locations.push({
                addressOrder: context.state.project.locations.length + 1,
                address: '',
                placeName: '',
                coordinates: {
                    lat: undefined,
                    lng: undefined
                },
                stairsFlightsCount: 0,
                hasElevator: false,
                additionalInformation: '',
                needsCarryCargo: false,
                contact: null,
                country: '',
                state: '',
                city: '',
            })
        },
        getCityStateFromZipCode: function(context) {
            return new Promise(function(resolve, reject) {
                axios.get('/trucks/geolocation/search-postal-code', {
                    params: context.getters.userCityStateRequestData
                }).then(function(response) {
                    if(response.data.status) {
                        context.commit('changeUserCityState', {
                            state: response.data.state,
                            cities: response.data.cities
                        })
                        resolve()
                    } else {
                        context.commit('changeUserCityState', {
                            state: '',
                            cities: ''
                        })
                        reject()
                    }
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        postNotInYourCity: function(context) {
            return new Promise(function(resolve, reject) {
                const projectAddressList = context.getters.projectAddressList
                const projectCoordinatesList = context.getters.coordinateList

                axios.post('/trucks/zone/saveUnsupportedLocations', {
                    name: context.state.user.firstName,
                    email: context.state.user.email,
                    pickup: JSON.stringify({
                        country: projectAddressList[0].country,
                        state: projectAddressList[0].state,
                        city: projectAddressList[0].city,
                        latitude: projectCoordinatesList[0].latitude,
                        longitude: projectCoordinatesList[0].longitude,
                    }),
                    dropoff: JSON.stringify({
                        country: projectAddressList[1].country,
                        state: projectAddressList[1].state,
                        city: projectAddressList[1].city,
                        latitude: projectCoordinatesList[1].latitude,
                        longitude: projectCoordinatesList[1].longitude
                    })
                }).then(function(response) {
                    let message = (response.data.status || response.data.message) ? response.data.message : 'Something went wrong. Try again in a moment.'
                    context.commit('setToast', message)
                    resolve()
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        bookNow: function(context) {
            return new Promise(function(resolve, reject) {
                axios.post('/trucks/job/book_truck',
                    context.getters.projectRequestData).then(function(response) {
                    if(response.data.status) {
                        context.commit('setSuccessfullyBooked')
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                }).catch(function(errorResponse) {
                    reject({
                        message: "Could not complete your order. Try again and if error persists " +
                            "contact customer service."
                    })
                })
            })
        },
        getUserCard: function(context) {
            return new Promise(function(resolve, reject) {
                axios.get('/trucks/user/card').then(function(response) {
                    if(response.data.status) {
                        context.commit('setUserCardOnFile', response.data.card_on_file)
                        if(response.data.is_outdated) {
                            // let them know that the card is outdated
                        }
                        resolve(response.data)
                    } else {
                        reject(response.data.error_type)
                    }
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        updateUserCard: function(context) {
            return new Promise(function(resolve, reject) {
                axios.post('/trucks/user/addOrUpdatePaymentMethod',
                    context.getters.updateCreditCardRequestData).then(function(response) {
                    if(response.data.status) {
                        resolve(response.data)
                    } else {
                        reject(response.data.message)
                    }
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        },
        registerCustomerSimple: function(context) {
            return new Promise(function(resolve, reject) {
                axios.post('/trucks/customer/register_customer_simple',
                    context.getters.newUserRequestData).then(function(response) {
                    if(response.data.status) {
                        resolve(response.data.user_id)
                    } else {
                        reject(response.data.message)
                    }
                }).catch(function(errorResponse) {
                    reject()
                })
            })
        }
    },
    getters: {
        currentDateTime: function(state) {
            return moment().tz(state.project.firstLocationTimeZone)
        },
        projectDateTime: function(state) {
            if(!state.project.date) {
                return undefined
            }

            // If time is not passed, moment subtracts 1 day of the date,
            // it's probably considering 00:00 as the default time.
            let defaultTimeInSpecifiedTimezone = !(state.project.time) ?
                moment().tz(state.project.firstLocationTimeZone).format('HH:mm') :
                state.project.time

            let dateArray = state.project.date.split('-')
            return moment(state.project.date, 'YYYY-MM-DD').tz(state.project.firstLocationTimeZone)
                .set('year', dateArray[0])
                .set('month', dateArray[1] - 1)
                .set('date', dateArray[2])
                .set('hour', moment(defaultTimeInSpecifiedTimezone, 'HH:mm').format('HH'))
                .set('minute', moment(defaultTimeInSpecifiedTimezone, 'HH:mm').format('mm'))
        },
        projectDateTimeString: function(state, getters) {
            const date = getters.projectDateTime
            if(!date) {
                return ''
            }

            return date.format("YYYY-MM-DD HH:mm:ss")
        },
        projectVehicleTypeAllowsHelper: function(state) {
            const vehicleType = state.availableVehicleTypeList.find(function(value) {
                return value.vehicle_type_id === state.project.vehicleType
            })

            return vehicleType ? !!parseInt(vehicleType.allow_helper) : undefined
        },
        projectSelectedEquipment: function(state) {
            return state.availableEquipment.filter(function(equipment, i) {
                return state.project.selectedEquipment.includes(equipment.id)
            })
        },
        equipmentRequiredByCargo: function(state) {
            let equipmentList = []
            state.project.cargoItems.forEach(cargoItem => {
                if(cargoItem.item.id !== null) {
                    cargoItem.item.requiredEquipmentList.forEach(equipment => {
                        if(equipmentList[equipment.equipmentId] === undefined) {
                            equipmentList[equipment.equipmentId] = []
                        }
                        equipmentList[equipment.equipmentId].push(cargoItem.item.name)
                    })
                }
            })
            return equipmentList
        },
        projectVehicleType: function(state, getters) {
            return getters.category !== null ?
                (getters.category.alias === 'labor_only' ? 15 : (
                    getters.category.alias === 'courier' ? 23 : state.project.vehicleType
                )) : null
        },
        projectJobDriverHelperList: function(state, getters) {
            let driverHelperList = [{
                vehicle_id: getters.projectVehicleType,
                estimated_distance: state.project.route.distance,
                estimated_time: state.project.route.duration,
                price_multiplier: state.project.priceMultiplier.multiplier,
                equipment_ids: state.project.selectedEquipment,
                user_type: 'driver'
            }]

            if(parseInt(state.project.projectType) !== PROJECT_TYPE_ONE_DELIVERY_PRO_ONE_TRUCK) {
                driverHelperList.push({
                    vehicle_id: 15,
                    estimated_distance: state.project.route.distance,
                    estimated_time: state.project.route.duration,
                    price_multiplier: state.project.priceMultiplier.multiplier,
                    equipment_ids: [],
                    user_type: 'helper'
                })
            }
            return driverHelperList
        },
        projectAddressList: function(state) {
            return state.project.locations.length > 0 ? state.project.locations.map(function(elem) {
                let addressComponents = (elem['addressComponents'] && elem.addressComponents['fromGoogle']) ? elem.addressComponents : extractGoogleAddressComponents(elem.addressComponents)
                return Object.assign({
                    placeId: elem.placeId,
                    address: elem.address,
                    place_name: elem.placeName,
                    placeTypes: elem.placeTypes,
                    address_order: elem.addressOrder,
                    coordinates: elem.coordinates.lat + ',' + elem.coordinates.lng,
                    stairs_flights_count: elem.hasElevator ? 0 : elem.stairsFlightsCount,
                    has_elevator: elem.hasElevator ? 1 : 0,
                    additional_information: elem.additionalInformation,
                    customerSelectedAddress: elem.customerSelectedAddress,
                    contact: elem.contact,
                }, addressComponents)
            }) : []
        },
        coordinateList: function(state) {
            return state.project.locations.map(function(elem) {
                return {
                    latitude: elem.coordinates.lat,
                    longitude: elem.coordinates.lng
                }
            })
        },
        projectCargoList: function(state) {
            return state.project.cargoItems.map(function(item) {
                let formattedItem = {
                    quantity: item.quantity,
                    user_input_name: item.item.name,
                }

                if(item.item.id) {
                    formattedItem.item_id = item.item.id
                } else {
                    formattedItem.cargo_weight_id = item.estimatedWeight
                }

                if(state.project.locations.length > 2) {
                    formattedItem.pickup_project_address_order = item.loadAddressOrder
                    formattedItem.dropoff_project_address_order = item.unloadAddressOrder
                }

                return formattedItem
            })
        },
        projectEstimateRequestData: function(state, getters) {
            return {
                project_address_list: JSON.stringify(getters.projectAddressList),
                promocode: state.project.promoCode,
                driver_helper_list: JSON.stringify(getters.projectJobDriverHelperList),
                cargo_list: JSON.stringify(getters.projectCargoList)
            }
        },
        projectRequestData: function(state, getters) {
            let requestBody = new FormData()
            if(!state.project.estimate) {
                return requestBody
            }

            requestBody.append('request_origin', 'DESKTOP_WEB')
            requestBody.append('job_type', state.project.projectType)
            requestBody.append('customer_id', state.user.id)

            requestBody.append('project_address_list', JSON.stringify(getters.projectAddressList))
            requestBody.append('driver_helper_list', JSON.stringify(getters.projectJobDriverHelperList))
            requestBody.append('job_cargo_items', JSON.stringify(getters.projectCargoList))

            requestBody.append('pricing_model', state.project.estimate.pricing_model)

            if(state.project.estimate.upfront_price && state.project.estimate.upfront_estimated_duration) {
                requestBody.append('upfront_price', state.project.estimate.upfront_price)
                requestBody.append('upfront_estimated_duration', JSON.stringify(state.project.estimate.upfront_estimated_duration))
                requestBody.append('total_price_displayed_in_booking',
                    state.project.estimate.discounted_upfront_prices && state.project.estimate.discounted_upfront_prices.upfront_price ?
                        state.project.estimate.discounted_upfront_prices.upfront_price :
                        state.project.estimate.upfront_price)
            }

            requestBody.append('additional_info', state.project.cargoAdditionalInfo)
            requestBody.append('booking_date', getters.projectDateTimeString)
            requestBody.append('min_quote', state.project.estimate.min_price)
            requestBody.append('max_quote', state.project.estimate.max_price)
            requestBody.append('promocode', state.project.promoCode) // TODO replace with getter
            requestBody.append('weight', '')
            requestBody.append('device_data', state.deviceData)

            if(state.clonedFromProjectId != null) {
                requestBody.append('clonedFromProjectId', state.clonedFromProjectId)
            }

            // requestBody.append('items_description', '')
            // TODO add location when booking

            if(state.project.images.length > 0) {
                for(let i = 0; i < state.project.images.length; i++) {
                    requestBody.append('files[]', state.project.images[i].file)
                }
            }

            return requestBody
        },
        userSignedIn: function(state, getters) {
            return !!state.user.id
        },
        userCityStateRequestData: function(state) {
            return {
                country: state.user.country,
                postal_code: state.user.zipCode,
                origin: 'booking-store'
            }
        },
        category: function(state) {
            return (state.categoryIndex === undefined || state.categories.length === 0 || state.categoryIndex >= state.categories.length || state.categoryIndex < 0) ? null : state.categories[state.categoryIndex]
        },
        newUserRequestData: function(state) {
            return {
                first_name: state.user.firstName,
                last_name: state.user.lastName,
                business_name: state.user.businessName,
                email: state.user.email,
                password: state.user.password,
                phone_number: state.user.phone,
                state: state.user.state,
                city: state.user.city,
                country: state.user.country,
                zipcode: state.user.zipCode,
                device_type: '',
                source: 'W',
                device_data: state.deviceData,
                google_recaptcha: state.signUpGoogleRecaptcha
            }
        },
        updateCreditCardRequestData: function(state) {
            const expirationDate = state.user.card.expirationDate
            return {
                user_id: state.user.id,
                card_number: state.user.card.number,
                name_on_card: state.user.card.nameOnCard,
                expiration_month: expirationDate ? expirationDate.split('/')[0] : '',
                expiration_year: expirationDate ? expirationDate.split('/')[1] : '',
                cvv: state.user.card.cvv,
                zipcode: state.user.card.zipCode?.replace(/-|\s/g,""),
                google_recaptcha: state.creditCardGoogleRecaptcha,
                device_data: state.deviceData,
            }
        },
        getLocation: function(state) {
            return function(addressOrder) {
                for(var i = 0; i < state.project.locations.length; i++) {
                    if(state.project.locations[i].addressOrder === addressOrder) {
                        state.project.locations[i].index = i
                        return state.project.locations[i]
                    }
                }
            }
        },
        getLocations: function(state) {
            return state.project.locations
        },
        analyticsEvent: function(state, getters) {
            const pages = [
                {step: 'booking/step1', title: 'Booking step 1: Where', firebaseEvent: 'started_booking_request'},
                {step: 'booking/step2', title: 'Booking step 2: What', firebaseEvent: 'selected_locations'},
                {
                    step: 'booking/step4',
                    title: 'Booking step 4: Vehicle info',
                    firebaseEvent: 'defined_what_are_we_moving'
                },
                {step: 'booking/step5', title: 'Booking step 5: Equipment', firebaseEvent: 'selected_vehicles'},
                {step: 'booking/step6', title: 'Booking step 6: Review', firebaseEvent: 'entered_checkout'},
                {step: 'booking/complete', title: 'Booking Complete', firebaseEvent: 'completed_booking'}
            ]

            const pageIndex = state.currentStep - 1

            const analytics = {
                step: pages[pageIndex].step,
                title: pages[pageIndex].title,
            }

            const listedCargoItemsList = getters.projectCargoList.length > 0 ?
                getters.projectCargoList.filter(item => item.hasOwnProperty("item_id")) : []
            let listedCargoItems = "null"
            if(listedCargoItemsList.length > 0) {
                listedCargoItems = {
                    totalQuantity: listedCargoItemsList.reduce((acc, item) => acc + item.quantity, 0),
                    cargoItemIds: listedCargoItemsList.map(item => item.item_id),
                }
            }

            const unlistedCargoItemsList = getters.projectCargoList.length > 0 ?
                getters.projectCargoList.filter(item => !item.hasOwnProperty("item_id")) : []
            let unlistedCargoItems = "null"
            if(unlistedCargoItems.length > 0) {
                unlistedCargoItems = {
                    totalQuantity: unlistedCargoItemsList.reduce((acc, item) => acc + item.quantity, 0),
                    cargoWeightIds: unlistedCargoItemsList.map(item => +item.cargo_weight_id)
                }
            }

            const projectAddressList = getters.projectAddressList.length > 0 ?
                getters.projectAddressList.map((address) => {
                    const coordinates = address.coordinates.split(",")

                    return {
                        addressOrder: address.address_order,
                        coordinates: {
                            latitude: parseFloat(coordinates[0]),
                            longitude: parseFloat(coordinates[1])
                        },
                        city: address.city,
                        state: address.state,
                        country: address.country,
                        stairsFlightsCount: address.stairs_flights_count,
                        hasElevator: address.has_elevator
                    }
                }) : "null"

            const firebaseEventData = {
                zoneId: state.project.zoneId || "null",
                projectPricing: state.project.pricingModel || "null",
                priceMultiplier: state.project.priceMultiplier.multiplier || "null",
                promoCode: state.project.promoCode || "null", // this parameter should be deprecated in favor of `coupon`
                subProjectList: getters.projectJobDriverHelperList.map((subProject) => {
                    return {
                        vehicleTypeId: subProject.vehicle_id,
                        equipmentList: subProject.equipment_ids,
                        estimate: {
                            distance: subProject.estimated_distance,
                            drivingTime: subProject.estimated_time
                        }
                    }
                }),
                listedCargoItems,
                unlistedCargoItems,
                projectAddressList
            }

            let firebaseEventDataString = JSON.stringify(firebaseEventData)
            let firebaseEventDataChunks = firebaseEventDataString.match(/(.|[\r\n]){1,100}/g)

            const firebaseEvent = {
                event: pages[pageIndex].firebaseEvent,
                data: {
                    project_package_length: firebaseEventDataChunks.length,
                    checkout_step: state.currentStep || "null",
                    coupon: state.project.promoCode || "null",
                    ...(firebaseEventDataChunks.reduce((arr, chunk, i) => {
                        return Object.assign(arr, {
                            [`project_package_${i}`]: chunk
                        })
                    }, []))
                }
            }

            return new GsAnalyticsEvent(firebaseEvent, analytics)
        },
        projectFilesPreviewSrcList: function(state) {
            return state.project.images.map(function(file) {
                return !/.pdf$/i.test(file.file.name) ? file.src : 'https://static.goshare.co/icons/pdf-file.svg'
            })
        }
    }
})

Vue.component('gs-cargo-autocomplete', {
    template: `<v-autocomplete
                label="Cargo item name"
                no-filter
                no-data-text="Enter the name for suggestions"
                hide-no-data
                persistent-hint
                :loading="isLoading"
                :items="itemList"
                :value-comparator="listItemComparator"
                :hint="($root.selectedItem.item.notice) != null ? $root.selectedItem.item.notice: ''"
                :search-input.sync.trim="searchString"
                v-model="$root.selectedItem.item"
                v-on:change="autoCompleteChanged($event)"
                :rules="[validationRules.requiredCargo, validationRules.notAllowedItem($root.selectedItem.item)]">
                </v-autocomplete>`,
    data: function() {
        return {
            isLoading: false,
            searchHits: [],
            searchString: null
        }
    },
    props: [],
    computed: {
        itemList: function() {
            if(this.searchString === null || this.searchString.length === 0) {
                return [];
            }

            const vm = this;
            let isSearchInAutocompleteEntries = this.searchHits.find(function(entry) {
                return entry && entry.name && entry.name.toLowerCase() === vm.searchString.trim().toLowerCase();
            }) !== undefined;

            let entries = this.searchHits.map(function(entry) {
                return {
                    text: entry.name,
                    value: {
                        id: +entry.item_id,
                        name: entry.name
                    }
                };
            });

            if(!isSearchInAutocompleteEntries && this.searchString.trim().length > 0) {
                const customItem = this.capitalizeFirstLetter(this.searchString);
                entries.push({
                    text: customItem,
                    value: {
                        id: null,
                        name: customItem
                    }
                });
            }

            return entries;
        }
    },
    watch: {
        searchString: function(value) {
            if(value === null || !value.trim()) {
                return;
            }

            this.isLoading = true;

            const vm = this;
            axios.get('https://khwxjebmh4.execute-api.us-west-2.amazonaws.com/cargoitems', {
                params: {
                    q: vm.formatQuery(value)
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'x-api-key': 'i1jj1B8uN278UgRBA6jLM4QIYHWDpX882A4vXAOk',
                }
            }).then(function(response) {
                vm.isLoading = false;

                vm.searchHits = response.data.hits.hit.map(function(x) {
                    return x.fields;
                })

            });
        }
    },
    methods: {
        /**
         * Given a string 'red chair' return 'red|chair|red*|chair*'
         * @param search
         * @returns {string}
         */
        formatQuery: function(search) {
            return search.split(' ').concat(search.split(' ').map(function(x) {
                return x + '*';
            })).join('|');
        },
        capitalizeFirstLetter: function(string) {
            if(string === null || string === undefined) {
                string = '';
            }
            return string.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase();
            });
        },
        listItemComparator: function(itemOne, itemTwo) {
            return itemOne && itemTwo &&
                ((itemOne.id === itemTwo.id) || (itemOne.name === itemTwo.name));
        },
        autoCompleteChanged: function(event) {
            const vm = this;
            this.isLoading = true;

            if(event.id != null) {
                axios.get('/trucks/cargo-items/' + event.id).then(function(response) {
                    vm.$root.selectedItem.item.allowedAssembly = response.data.data.allowedAssembly;
                    vm.$root.selectedItem.item.requiresHelper = response.data.data.requiresHelper;
                    vm.$root.selectedItem.item.allowedItem = response.data.data.allowedItem;
                    vm.$root.selectedItem.item.notice = response.data.data.notice;
                    vm.$root.selectedItem.item.requiredEquipmentList = response.data.data.requiredEquipmentList;
                }).then(function(response) {
                    vm.isLoading = false;
                });
            }
        }
    }
});

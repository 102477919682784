Vue.component('gs-location-details', {
    template: "<div>\n" +
        "    <p class=\"caption grey--text d-block mb-0\">{{ formatNumberWithOrdinalSuffix(location.addressOrder) + ' Location' }}</p>\n" +
        "    <p>\n" +
        "        {{location.placeName ? location.placeName + ', ': ''}}{{location.address}}<br>\n" +
        "        <v-dialog\n" +
        "                ref=\"locationDetailsDialog\"\n" +
        "                v-model=\"dialog\"\n" +
        "                width=\"500px\">\n" +
        "            <template v-slot:activator=\"{ on }\">\n" +
        "                <a v-on=\"on\" class=\"overline\">Details</a>\n" +
        "            </template>\n" +
        "\n" +
        "            <v-card>\n" +
        "                <v-card-title>{{location.address}}</v-card-title>\n" +
        "                <v-card-text>\n" +
        "    <p>\n" +
        "        <span class=\"font-weight-medium\">Stairs</span><br>\n" +
        "        <span v-html=\"location.needsCarryCargo && !location.hasElevator ? 'Delivery pro needs to take <b>' + location.stairsFlightsCount + '</b> flight' + (location.stairsFlightsCount > 1 ? 's' : '') + ' of stairs': 'No stairs'\"></span>\n" +
        "    </p>\n" +
        "    <p>\n" +
        "        <span class=\"font-weight-medium\">Elevator</span><br>\n" +
        "        <span v-html=\"location.needsCarryCargo && location.hasElevator ? 'Delivery pro can use an elevator' : 'No elevator available'\"></span>\n" +
        "    </p>\n" +
        "    <p>\n" +
        "        <span class=\"font-weight-medium\">Additional information</span><br>\n" +
        "        <span v-html=\"location.additionalInformation.length > 0 ? location.additionalInformation : 'Not provided'\"></span>\n" +
        "    </p>\n" +
        "    </v-card-text>\n" +
        "    </v-card>\n" +
        "\n" +
        "    </v-dialog>\n" +
        "    </p>\n" +
        "</div>",
    props: ['location'],
    data: function() {
        return {
            dialog: false
        };
    },
    methods: {
        formatNumberWithOrdinalSuffix: function(number) {
            let s = ["th", "st", "nd", "rd"],
                v = number % 100;
            return number + (s[(v - 20) % 10] || s[v] || s[0]);
        },
    }
});

Vue.component('gs-error-dialog', {
    template: "<v-dialog\n" +
        "            v-model=\"show\"\n" +
        "            width=\"600px\"\n" +
        "            persistent\n" +
        "            >\n" +
        "            <v-card>\n" +
        "                <v-card-title>{{title}}</v-card-title>\n" +
        "                <v-card-text>\n" +
        "                    {{content}}\n" +
        "                </v-card-text>\n" +
        "                <v-card-actions>\n" +
        "                    <div class=\"flex-grow-1\"></div>\n" +
        "                    <v-btn color=\"blue darken-1\" text @click=\"close\">Ok</v-btn>\n" +
        "                </v-card-actions>\n" +
        "            </v-card>\n" +
        "        </v-dialog>",
    props: {
        show: {
            required: true,
            type: Boolean
        },
        title: {
            required: true,
            type: String
        },
        content: {
            required: true,
            type: String
        },
        callback: {
            type: Function,
            default: undefined
        }
    },
    methods: {
        close: function() {
            this.$emit('close-error-dialog');
            if(this.callback instanceof Function) {
                this.callback();
            }
        }
    }
})
var requestBraintreeDeviceData = function(onDataCollectorFinish) {
    braintree.client.create({
        authorization: braintreeTokenizationKey
    }).then(function(clientInstance) {
        return braintree.dataCollector.create({
            client: clientInstance,
            paypal: true
        }).then(function(dataCollectorInstance) {
            onDataCollectorFinish(dataCollectorInstance.deviceData);
        }).catch(function(error) {
            onDataCollectorFinish('');
        });
    }).catch(function(error) {
        onDataCollectorFinish('');
    });
}
Vue.mixin({
    data: function() {
        return {
            get validationRules() {
                return {
                    required: function(value) {
                        return !!value || 'Field is required';
                    },

                    email: function(value) {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Invalid e-mail';
                    },

                    passwordMatches: function(value) {
                        return value === this.userConfirmPassword || 'Passwords do not match'
                    },

                    // Todo: move to a local component.
                    requiredCargo: function(value) {
                        return !!value.name || 'Field is required';
                    },

                    // Todo: move to a local component.
                    requiredLocation: function(location) {
                        return !!location || 'An address is required';
                    },

                    // Todo: move to a local component.
                    locationAlreadySelected: function(cargo) {
                        return cargo.loadAddressOrder !== cargo.unloadAddressOrder || 'Unable to select same location for load and unload';
                    },

                    // Todo: move to a local component.
                    noPickupAtFinalStop: function(cargo, lastStopOrder) {
                        return cargo.loadAddressOrder !== lastStopOrder || 'Isn\'t possible to pickup items at final location';
                    },

                    // Todo: move to a local component.
                    noDropOffAtFirstStop: function(cargo) {
                        return cargo.unloadAddressOrder !== 1 || 'Isn\'t possible to delivery items at first location';
                    },

                    // Todo: move to a local component.
                    cargoListEmpty: function(cargoList) {
                        return cargoList.length !== 0 || 'At least one item is required';
                    },

                    // Todo: move to a local component.
                    cargoListWithNoLocationAssigned: function(cargoList) {
                        return !cargoList.some(cargo => cargo.loadAddressOrder == null || cargo.unloadAddressOrder == null) || 'Some item has no location assigned';
                    },

                    // Todo: move to a local component.
                    locationNotAssigned: function(projectAddressList, cargoList) {

                        let addressOrderList = projectAddressList.map(function(address) {
                            return address.addressOrder;
                        });

                        let matchItemLocationWithProjectOrder = [
                            ...cargoList.map(function(item) {
                                return item.loadAddressOrder;
                            }),
                            ...cargoList.map(function(item) {
                                return item.unloadAddressOrder;
                            }),
                        ].unique();

                        let diff = addressOrderList.diff(matchItemLocationWithProjectOrder)
                        let errorMessage = (diff.length === 1) ? 'Location ' + diff.naturalLanguageJoin() + ' has no item assigned' : 'Locations ' + diff.naturalLanguageJoin() + ' have no item assigned'

                        return (diff.length === 0 || cargoList.length === 0) || errorMessage;
                    },

                    notListedCargoWeightRequired: function(value) {
                        return value !== null || 'Select an estimated weight for one item';
                    },

                    notAllowedItem: function(item) {
                        return (item.allowedItem == null || item.allowedItem) || 'Sorry, we are unable to move this item';
                    },

                    atLeastOne: function(value) {
                        return value > 0 || 'At least one item is required';
                    }
                }
            }
        }
    },
    computed: {
        isMobile: function() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        invalidPromoCodeMessage: function() {
            let hasError = this.$store.state.project.promoCode !== undefined &&
                (this.$store.state.project.estimate || false) &&
                this.$store.state.project.estimate.discount !== '' &&
                this.$store.state.project.estimate.discount.status === false;
            let errorMessage = '';
            if(hasError) {
                errorMessage = (this.$store.state.project.estimate !== undefined
                    && this.$store.state.project.estimate.discount.details !== undefined) ?
                    this.$store.state.project.estimate.discount.details : 'Invalid promo code';
            }
            return hasError ? [errorMessage] : [];
        },
        validPromoCodeMessage: function() {
            if(this.$store.state.project.estimate &&
                this.$store.state.project.estimate.discount &&
                this.$store.state.project.estimate.discount.details &&
                this.$store.state.project.estimate.discount.status
            ) {
                return (this.$store.state.project.estimate || false) && this.$store.state.project.estimate.discount.details;
            }
        }
    },
    methods: {
        formatDate: function(date) {
            return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
        },
        formatHour: function(hour) {
            return moment(hour, 'HH:mm').format('h:mm A');
        }
    }
});
